<template>
    <fieldset class="marginBottom30">
        <legend>Matching Type</legend>

        <div class="radioField-multipleRadio-align">
            <div class="flex">
                <div v-for="(type, idx) in match_types" class="radioField flex alignFlexStart marginBottom10 marginRight20">
                    <div class="flex">
                        <input
                            :id="'matching-type-' + type"
                            type="radio"
                            :value="type"
                            name="matching-type"
                            :checked="type.toLowerCase() == options.matchingType"
                            @click="options.matchingType = type.toLowerCase()"
                        />
                        <label :for="'matching-type-' + type"> {{ type }}</label>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset class="marginBottom30">
        <legend>
            <b>Matching ({{ options.matchingType }})</b>
        </legend>

        <div class="marginBottom20" :class="{ flexOnly: !isBelow768px }">
            <div :class="{ 'col-xs-6 marginRight10': !isBelow768px, marginBottom20: isBelow768px }">
                <label class="control-label" for="prompt_title"> Prompt Title </label>
                <input id="prompt_title" v-model="options.labels.prompt" type="text" name="prompt_title" class="form-control" />
            </div>

            <div :class="{ 'col-xs-6 marginLeft10': !isBelow768px }">
                <label class="control-label" for="answer_title"> Answer Title </label>
                <input id="answer_title" v-model="options.labels.answer" type="text" name="answer_title" class="form-control" />
            </div>
        </div>

        <template v-for="(match, idx) of options.matches">
            <div
                :id="'match-group-' + (idx + 1)"
                :draggable="true"
                class="optionDiv"
                :class="{ marginBottom30: idx != options.matches.length - 1 }"
                tabIndex="-1"
                :aria-label="'Match group ' + (idx + 1) + ' of ' + options.matches.length"
                @drop="dropToOption($event, idx)"
                @dragover="allowDropToOption"
                @dragleave="leaveDragFromOption"
                @dragstart="dragOption($event, idx)"
                @drag="dragFunction"
                @dragend="stopScroll = true"
            >
                <div class="flexOnly alignFlexStart">
                    <button
                        v-if="!isMobileView"
                        v-tooltip="'Drag Match Group ' + (idx + 1)"
                        class="vertical6DotsButton marginRight10"
                        :aria-label="'Drag Match Group ' + (idx + 1)"
                    >
                        <i class="fas fa-grip-vertical" aria-hidden="true"></i>
                    </button>
                    <label class="marginRight10"> <span class="visible-hidden">Match Group</span>{{ idx + 1 }}. </label>
                    <div class="width100">
                        <div class="" :class="{ flexOnly: options.matchingType == 'one-to-one' && !isBelow768px }">
                            <div class="width100">
                                <div class="form-group marginBottom20">
                                    <label class="control-label" :for="'prompt_details_' + idx">
                                        <span class="visible-hidden">Match Group {{ idx + 1 }} </span>
                                        Prompt
                                    </label>
                                    <input
                                        :id="'prompt_details_' + idx"
                                        v-model="match.prompt.content"
                                        type="text"
                                        :name="'prompt_details_' + idx"
                                        class="form-control"
                                    />
                                </div>

                                <template v-for="(answer, answerIdx) in match.answer">
                                    <div
                                        :class="{
                                            flexOnly: !isBelow768px,
                                            marginTop20: answerIdx != 0,
                                        }"
                                    >
                                        <div class="form-group">
                                            <label class="control-label" :for="'answer_details_' + idx + '_' + answerIdx">
                                                <span class="visible-hidden">Match Group {{ idx + 1 }} </span>
                                                Answers

                                                <template v-if="options.matchingType == 'one-to-one'"> A </template>
                                                <template v-else> {{ String.fromCharCode(65 + answerIdx) }} </template>
                                            </label>
                                            <input
                                                :id="'answer_details_' + idx + '_' + answerIdx"
                                                v-model="answer.content"
                                                type="text"
                                                :name="'answer_details_' + idx + '_' + answerIdx"
                                                class="form-control"
                                            />
                                        </div>
                                        <div
                                            v-if="options.matchingType != 'one-to-one'"
                                            class="minWidth110px"
                                            :class="{
                                                'marginLeft20 paddingTop25': !isBelow768px,
                                                marginTop20: isBelow768px,
                                            }"
                                        >
                                            <button
                                                v-if="match.answer.length > 2"
                                                v-tooltip="'Delete answer ' + String.fromCharCode(65 + (answerIdx - 1)) + ' from match Group ' + (idx + 1)"
                                                class="btn btn-outline-danger"
                                                :class="{ 'width45px height45px': !isBelow768px }"
                                                :aria-label="'Delete answer ' + String.fromCharCode(65 + (answerIdx - 1)) + ' from match group ' + (idx + 1)"
                                                @click.prevent="removeAnswerFromMatchGroup(idx, answerIdx)"
                                            >
                                                <i class="fa fa-trash" :class="{ marginRight8: isBelow768px }" aria-hidden="true"></i>
                                                <template v-if="isBelow768px"> Delete Answer {{ String.fromCharCode(65 + (answerIdx - 1)) }} </template>
                                            </button>

                                            <button
                                                v-if="answerIdx == match.answer.length - 1"
                                                v-tooltip="'Add answer ' + String.fromCharCode(65 + answerIdx) + ' to match group ' + (idx + 1)"
                                                class="btn btn-outline-default"
                                                :class="{ 'width45px height45px': !isBelow768px }"
                                                :aria-label="'Add answer ' + String.fromCharCode(65 + answerIdx) + ' to match group ' + (idx + 1)"
                                                @click.prevent="addAnswerToMatchGroup(idx)"
                                            >
                                                <i class="fa fa-plus" :class="{ marginRight8: isBelow768px }" aria-hidden="true"></i>
                                                <template v-if="isBelow768px"> New Answer {{ String.fromCharCode(65 + answerIdx) }} </template>
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                class="minWidth110px"
                                :class="{
                                    'marginLeft20 paddingTop25': options.matchingType == 'one-to-one' && !isBelow768px,
                                    marginTop20: (options.matchingType != 'one-to-one' && !isBelow768px) || isBelow768px,
                                }"
                            >
                                <template v-if="options.matches.length > 2">
                                    <button
                                        v-tooltip="'Delete Match Group ' + (idx + 1)"
                                        class="btn btn-outline-danger"
                                        :class="{ 'width45px height45px': options.matchingType == 'one-to-one' && !isBelow768px }"
                                        :aria-label="'Delete Match Group ' + (idx + 1)"
                                        @click.prevent="removeMessage(idx)"
                                    >
                                        <i
                                            class="fa fa-trash"
                                            :class="{ marginRight8: (options.matchingType != 'one-to-one' && !isBelow768px) || isBelow768px }"
                                            aria-hidden="true"
                                        ></i>
                                        <template v-if="(options.matchingType != 'one-to-one' && !isBelow768px) || isBelow768px"> Delete Match </template>
                                    </button>
                                </template>
                                <button
                                    v-if="idx == options.matches.length - 1"
                                    v-tooltip="'Add Match Group ' + (idx + 2)"
                                    class="btn btn-outline-default"
                                    :class="{ 'width45px height45px': options.matchingType == 'one-to-one' && !isBelow768px }"
                                    :aria-label="'Add Match Group ' + (idx + 2)"
                                    @click.prevent="addOption()"
                                >
                                    <i
                                        class="fa fa-plus"
                                        :class="{ marginRight8: (options.matchingType != 'one-to-one' && !isBelow768px) || isBelow768px }"
                                        aria-hidden="true"
                                    ></i>
                                    <template v-if="(options.matchingType != 'one-to-one' && !isBelow768px) || isBelow768px"> New Match </template>
                                </button>
                            </div>
                        </div>

                        <div v-if="idx == match_group_idx" :id="'match-group-' + (idx + 1) + '-warning-dialog'" class="marginTop20" tabindex="-1">
                            <p>Are you sure you want to delete this match {{ idx + 1 }}?</p>
                            <div class="marginTop0">
                                <button class="btn btn-outline-default" @click.prevent="match_group_idx = -1">No, Cancel</button>
                                <button
                                    class="btn btn-danger"
                                    @click.prevent="
                                        removeOption(match_group_idx);
                                        match_group_idx = -1;
                                    "
                                >
                                    Yes, Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </fieldset>
</template>

<script setup>
defineEmits(['update:options']);
import { ref, nextTick, computed, onMounted } from 'vue';

const currentDraggedItem = ref(null);
const stopScroll = ref(true);
const key = ref();
const match_group_idx = ref();

const match_types = ref(['One-to-One', 'One-to-Many']);

const props = defineProps({
    options: {
        type: Array,
        default: function () {
            return {
                matchingType: '',
                matches: [
                    {
                        prompt: {
                            content: '',
                        },
                        answer: [
                            {
                                content: '',
                            },
                        ],
                    },
                ],
                labels: { prompt: '', answer: '' },
            };
        },
    },
    modal_idx: {
        type: String,
        default: null,
    },
});

const options = computed({
    // Use computed to wrap the object
    get: () => props.options,
    set: (value) => emit('update:options', value),
});

function dragFunction(e) {
    var scroll = function (step) {
        var scrollY = $(`#${props.modal_idx} .modal`).scrollTop();
        $(`#${props.modal_idx} .modal`).scrollTop(scrollY + step);
        if (!stopScroll.value) {
            setTimeout(function () {
                scroll(step);
            }, 20);
        }
    };

    stopScroll.value = true;

    if (e.clientY < 150) {
        stopScroll.value = false;
        scroll(-1);
    }
    if (e.clientY > $(window).height() - 150) {
        stopScroll.value = false;
        scroll(1);
    }
}

function leaveDragFromOption(event) {
    var target = event.target.closest('.optionDiv');
    if ($(currentDraggedItem.value).hasClass('optionDiv')) {
        $(target).removeClass('selected-bottom');
        $(target).removeClass('selected-top');
    }
}

function dropToOption(event, idx) {
    var data = JSON.parse(event.dataTransfer.getData('text'));
    if (data.from == 'option') {
        var target = event.target.closest('.optionDiv');
        $(target).removeClass('selected-bottom');
        $(target).removeClass('selected-top');
        array_move(options.value.matches, data.idx, idx);

        for (var i = 0; i < options.value.length; i++) {
            let key = String.fromCharCode(65 + i);
            options.value.matches[i].key = key.value;
        }
    }

    currentDraggedItem.value = null;
}

function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

function allowDropToOption(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    if ($(currentDraggedItem.value).hasClass('optionDiv')) {
        var target = event.target.closest('.optionDiv');
        if (!target.isSameNode(currentDraggedItem.value)) {
            var bounding = target.getBoundingClientRect();
            var offset = bounding.y + bounding.height / 2;
            if (event.clientY - offset > 0) {
                $(target).removeClass('selected-top');
                $(target).addClass('selected-bottom');
            } else {
                $(target).removeClass('selected-bottom');
                $(target).addClass('selected-top');
            }
        }
    }
}

function dragOption(event, idx) {
    event.dataTransfer.setData('text', JSON.stringify({ from: 'option', idx: idx }));
    event.dataTransfer.dropEffect = 'move';

    currentDraggedItem.value = event.target.closest('.optionDiv');
}

function removeMessage(idx) {
    $('#sr-message-manage-question').text('');

    const message = 'Match Group ' + (idx + 1) + ' dialog appear';

    setTimeout(() => {
        $('#sr-message-manage-question').text(message);
    }, 100);

    match_group_idx.value = idx;

    nextTick(() => {
        $('#match-group-' + (idx + 1) + '-warning-dialog').focus();
    });
}

function removeOption(idx) {
    $('#sr-message-manage-question').text('');

    const message = 'Removed match group ' + (idx + 1);

    setTimeout(() => {
        $('#sr-message-manage-question').text(message);
    }, 100);

    options.value.matches.splice(idx, 1);
}

function addOption() {
    let length = options.value.matches.length == 0 ? 1 : options.value.matches.length + 1;
    options.value.matches.push({
        prompt: {
            content: '',
        },
        answer: [
            {
                content: '',
            },
        ],
    });

    $('#sr-message-manage-question').text('');
    const message = 'Added new match group ' + options.value.matches.length;
    setTimeout(() => {
        $('#sr-message-manage-question').text(message);
    }, 100);

    nextTick(() => {
        $('#choice-' + options.value.matches.length + '-group').focus();
    });
}

function addAnswerToMatchGroup(matchGroupIdx) {
    options.value.matches[matchGroupIdx].answer.push({ content: '' });
}

function removeAnswerFromMatchGroup(matchGroupIdx, answerIdx) {
    options.value.matches[matchGroupIdx].answer.splice(answerIdx, 1);
}

const canSubmitAnswer = computed(() => {
    if (!(options.value.labels.prompt && options.value.labels.answer)) {
        return false;
    }

    for (var i = 0; i < options.value.matches.length; i++) {
        for (var j = 0; j < options.value.matches[i].length; j++) {
            console.log(options.value.matches[i][j].prompt.content);
            if (!options.value.matches[i][j].prompt.content) {
                return false;
            }

            for (var k = 0; k < options.value.matches[i][j].answer; k++) {
                if (!options.value.matches[i][j].answer[k].content) {
                    return false;
                }
            }
        }
    }
    return true;
});

onMounted(() => {
    if (options.value.matchingType == '') {
        options.value.matchingType = 'one-to-one';
    }
});

// expose data and method thingy
defineExpose({
    canSubmitAnswer,
});
</script>
