<template>
    <div class="row">
        <div>
            <div>
                <caption class="visible-hidden">
                    Question:
                </caption>
                <kr-math :input="question.question" :safe="!question.questionIsHTML" class="wordBreakWord" />
            </div>

            <!-- grib base -->
            <div v-if="question.type == 'rating'" class="gridBase overFlowAuto">
                <table class="transparent table marginTop20">
                    <thead>
                        <tr>
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <th class="width150px">
                                    {{ label.weight }}
                                </th>
                            </template>
                        </tr>
                        <tr v-if="hasLabel">
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <th class="textWrap">
                                    {{ label.label }}
                                </th>
                            </template>
                        </tr>
                        <tr v-if="hasRubric">
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <th class="whiteSpacePreLine">
                                    <div>{{ label.rubric }}</div>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <td>
                                    <div class="col-xs-12 gradient btn-default mainBtn radioField align-items justifyCenter flexOnly">
                                        <input type="radio" class="margin0" disabled />
                                        <!-- <label></label> -->
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>

            <template v-if="question.type == 'mcqs' || question.type == 'mcqm' || question.type == 'binary'">
                <div tabindex="-1" class="questionBankOptionsField">
                    <caption class="visible-hidden">
                        There are
                        {{
                            question.options.length
                        }}
                        answers choice
                    </caption>
                    <div
                        v-for="(option, idx) in question.options"
                        :key="option + '_' + idx"
                        :class="{
                            correctOption: option.isCorrect && showCorrectAnswer,
                            newCorrect: optionCorrect(question, option.key) && showCorrectAnswer,
                            paddingLeft35px: !(option.isCorrect || optionCorrect(question, option.key)) && showCorrectAnswer,
                        }"
                        class="checkboxField flex marginTop10"
                    >
                        <caption v-if="(option.isCorrect || optionCorrect(question, option.key)) && showCorrectAnswer" class="visible-hidden">
                            option
                            {{
                                option.key
                            }}
                            is correct
                        </caption>

                        <div v-if="(option.isCorrect || optionCorrect(question, option.key)) && showCorrectAnswer" class="marginRight10">
                            <i v-if="question.type == 'mcqs'" class="fa fa-check-circle" aria-hidden="true" />
                            <i v-if="question.type == 'mcqm'" class="fa fa-check-square" aria-hidden="true" />
                        </div>
                        <label :for="question.uuid + '-' + option.key">
                            <span class="withOptionKey" :aria-label="'option ' + option.key"> {{ option.key }}. </span>
                            <span class="withAnswerKey">
                                <kr-math :input="option.content" :safe="!option.contentIsHTML" class="question" />
                            </span>
                        </label>
                    </div>
                </div>
            </template>

            <template v-if="question.type == 'vsa'">
                <div class="marginTop20">
                    <h3 class="fontBlack fs-18px marginBottom5">
                        <i class="fa fa-check-circle successColor marginRight8" aria-hidden="true" /><b>Correct Answers</b>
                    </h3>

                    <p>
                        <template v-if="question.vsaSettings.correctAnswers == null"> There is no correct answer </template>
                        <template v-for="(correctAnswer, idx) in question.vsaSettings.correctAnswers" v-else>
                            {{ correctAnswer }}
                            <template v-if="idx != question.vsaSettings.correctAnswers.length - 1"> , </template>
                        </template>
                    </p>
                </div>

                <div class="marginTop20">
                    <h3 class="fontBlack fs-18px marginBottom5">
                        <i class="fa fa-times-circle dangerColor marginRight8" aria-hidden="true" /><b>Wrong Answers</b>
                    </h3>
                    <p>
                        <template v-if="question.vsaSettings.wrongAnswers == null"> There is no wrong answer </template>
                        <template v-for="(wrongAnswer, idx) in question.vsaSettings.wrongAnswers" v-else>
                            {{ wrongAnswer }}
                            <template v-if="idx != question.vsaSettings.wrongAnswers.length - 1"> , </template>
                        </template>
                    </p>
                </div>
            </template>

            <fieldset v-if="question.type == 'sequence'" class="marginTop20">
                <legend>Sequence Order</legend>

                <template v-for="(option, option_idx) in question.options">
                    <div class="sequencing-div-instructor" :class="{ displayGrid: !isMobileView }">
                        <div :class="{ 'grid-order-2': !isMobileView }">
                            <p :id="'sequence-instructor-' + (option_idx + 1)">{{ option.content }}</p>
                        </div>

                        <div :class="{ 'grid-order-1': !isMobileView, marginTop20: isMobileView }">
                            <label :for="'sequence-instructor-' + (option_idx + 1)" class="correct">
                                <span> Correct Order </span>
                                <br v-if="!isMobileView" />
                                {{ option.order }}
                            </label>
                        </div>
                    </div>
                </template>
            </fieldset>

            <fieldset v-if="question.type == 'matching'" class="marginTop20">
                <legend>Matching ({{ question.options.matchingType }})</legend>
                <template v-for="(match, idx) in question.options.matches">
                    <div class="sequencing-div-instructor">
                        <div>
                            <label :for="'matching-one-to-one-instructor-prompt-' + (idx + 1)" class="control-label capitalize">
                                {{ question.options.labels.prompt }} {{ idx + 1 }}
                            </label>

                            <p :for="'matching-one-to-one-instructor-prompt-' + (idx + 1)">
                                {{ match.prompt.content }}
                            </p>
                        </div>

                        <div class="marginTop20">
                            <label :for="'matching-one-to-one-instructor-answer-' + (idx + 1)" class="control-label capitalize">
                                {{ question.options.labels.answer }} for {{ question.options.labels.prompt }} {{ idx + 1 }}
                            </label>

                            <template v-for="(answer, ansIdx) in match.answer">
                                <div class="matching-answer flexOnly categorize-correct">
                                    <i class="fa fa-check-circle marginRight8 paddingTop2" aria-hidden="true" />
                                    <p :for="'matching-one-to-one-instructor-answer-' + (ansIdx + 1)">{{ answer.content }}</p>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </fieldset>
        </div>
    </div>
</template>
<script>
export default {
    props: ['question', 'question_no_label', 'options', 'testObj'],
    data() {
        return {
            canHover: false,
            showCorrectAnswer: true,
        };
    },
    computed: {
        hasLabel() {
            if (this.question.type == 'rating') {
                var ratingSettings = this.question.ratingSettings ? this.question.ratingSettings : [];
                for (var i = 0; i < ratingSettings.length; i++) {
                    if (ratingSettings[i].label != '') {
                        return true;
                    }
                }
            }
            return false;
        },
        hasRubric() {
            if (this.question.type == 'rating') {
                var ratingSettings = this.question.ratingSettings ? this.question.ratingSettings : [];
                for (var i = 0; i < ratingSettings.length; i++) {
                    if (ratingSettings[i].rubric != '') {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    created() {
        this.canHover = _.get(this.options, 'canHover', false);
        this.showCorrectAnswer = _.get(this.options, 'showCorrectAnswer', true);
    },
    methods: {
        emit(event, data = []) {
            this.$emit(event, data);
        },
        optionCorrect(question, key) {
            if (!this.testObj) {
                return false;
            }
            if (['tests.clarification', 'activities.tests.dashboard.trat-presentation'].includes(this.$route.name)) {
                return false;
            }

            if (
                (['ended', 'completed'].includes(this.testObj.status) ||
                    ['tests.irat-report', 'tests.trat-report', 'tests.application-report'].includes(this.$route.name)) &&
                question.type == 'mcqs'
            ) {
                for (var i = 0; i < this.testObj.acceptedNewAnswers.length; i++) {
                    if (this.testObj.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        for (var j = 0; j < this.testObj.acceptedNewAnswers[i].acceptedAnswers.length; j++) {
                            if (this.testObj.acceptedNewAnswers[i].acceptedAnswers[j] == key) {
                                return true;
                            }
                        }
                    }
                }
            }
        },
    },
};
</script>
<style scoped lang="scss">
.qnsOptions {
    margin-top: 20px;
}

.optionKey {
    font-weight: bold;
}

.gridBase table {
    table-layout: fixed;
    background: none;
}

.transparent.table,
table.transparent th,
table.transparent td {
    border: 0;
    box-shadow: none;
    text-align: center;
}

.transparent.table thead tr {
    background-image: none;
    background: none;
    box-shadow: none;
}

table.transparent tbody tr td {
    padding: 20px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:first-child td {
    padding: 20px 0 10px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:last-child td {
    padding-bottom: 0;
}

table.transparent td:last-child div {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table > thead > tr > th {
    vertical-align: middle;
}

.transparent.table > thead > tr:first-child > th {
    padding: 5px 20px 10px;
}

.transparent.table > thead > tr:last-child > th {
    padding: 5px 20px 0;
}

.transparent.table td:first-child div.gradient.btn-default {
    border-left: 1px solid #d8d8d8;
    border-right: 0;
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.transparent.table td:not(:first-child) div.gradient.btn-default {
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    border-radius: 0;
    box-shadow: none;
}

.transparent.table td:last-child div.gradient.btn-default {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table td:nth-child(2):last-child div.gradient.btn-default {
    border: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) th {
    border: 1px solid #d8d8d8;
    border-right: 0;
    padding: 20px;
    text-transform: none;
}

.transparent.table thead tr:nth-child(3) th:last-child {
    border-right: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) {
    margin-bottom: 10px;
}

.gridBase .profile_image.marginRight5 {
    margin-right: 5px;
}

#exTab1 .nav-pills > li > a {
    width: 250px;
    border-radius: 0;
    padding: 20px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 1px solid #fff;
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    color: #222;
}

#exTab1 .nav-pills > li.active > a {
    border: 1px solid #0071be;
    background-image: none;
    background: #fff;
    border-bottom: none;
    border-top: 3px solid #0071be;
}

#exTab1 .tab-content {
    padding: 20px;
    border: 1px solid #0071be;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fff;
}

.numberInput {
    position: relative;
    width: 185px;
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-nav .disabled {
    color: #dfe3e9;
    cursor: not-allowed;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    background-color: white !important;
    /* border: 1px solid #dfe3e9 !important; */
    border: 1px solid #717171 !important;
}

.quantity-button:last-child {
    /* border: 1px solid #dfe3e9 !important; */
    border-top: 1px solid #717171 !important;
}

.width150px {
    width: 150px;
}

.questionBankOptionsField {
    .checkboxField label {
        align-items: baseline;
    }

    .fa-check-circle,
    .fa-check-square {
        font-size: 18px;
    }
}
</style>
