<template>
    <div
        v-if="component_done_loading"
        :id="question.uuid + '-question'"
        class="panel"
        :class="{
            'night-mode':
                question.type == 'openended' &&
                ['ongoing', 'ended', 'released'].includes(question.eGallery.status) &&
                $route.name == 'activities.tests.questions',
        }"
    >
        <div class="hidden-xs hidden-sm panel-heading flexSpaceBetween">
            <div class="flex align-items">
                <div class="flexOnly align-items">
                    <div class="marginRight8">
                        <label>Question {{ getQuestionNo(question_group_idx, question_idx) }}</label>
                    </div>
                    <div v-if="question.remarks && !showRealtimeToggle">
                        <button class="btn btn-default" @click.prevent="openFacultyNotesModal(question)">
                            <i class="fa-regular fa-note-sticky marginRight8" aria-hidden="true"></i>Question Notes
                        </button>
                    </div>
                </div>
                <question-pagination :test-obj="testObj" />
                <template v-if="question.type == 'openended' && showRealtimeToggle">
                    <div
                        v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: !canStartEgallery
                                ? 'Please select at least two teams’ answers to enable e-Gallery Walk'
                                : '' || hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-primary mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 || !canStartEgallery || question.displayAnswer == false ? true : undefined"
                            @click="prepare()"
                        >
                            Initialize e-Gallery Walk
                        </button>
                    </div>
                    <!-- <div
            class="btn"
            :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
            v-tooltip="{
              content:
                hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
            }"
            v-if="['preparing'].includes(question.eGallery.status)"
            @click="openStartConfirmationModal()"
          >
            <button
              class="btn btn-success mainBtn"
              aria-label="Start eGallery Walk"
            >
              Start eGallery Walk&nbsp;<i class="fa fa-caret-right"></i>
            </button>
          </div> -->
                    <div
                        v-if="['ongoing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-danger mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'Stop e-Gallery Walk Disabled' : 'Stop e-Gallery Walk'"
                            @click="openEndConfirmationModal()"
                        >
                            Stop e-Gallery Walk<i class="fa fa-stop marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                    <div
                        v-if="['paused'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-success mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'Resume e-Gallery Walk Disabled' : 'Resume e-Gallery Walk'"
                            @click="resume()"
                        >
                            Resume e-Gallery Walk-Gallery Walk&nbsp;<i class="fa fa-caret-right" />
                        </button>
                    </div>
                    <div
                        v-if="['ended'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-success mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'Release e-Gallery Walk Disabled' : 'Release e-Gallery Walk'"
                            @click="openReleaseConfirmationModal()"
                        >
                            Release e-Gallery Walk Results<i class="fa fa-check marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                </template>
            </div>
            <div v-if="!['openended'].includes(question.type)" class="buttonGroupInline">
                <div class="switchToggle flexOnly align-items marginRight20">
                    <label :for="'showCorrectAnswer-' + question.uuid" class="control-label marginBottom0 marginTop10"> Show Correct Answer </label>
                    <label
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="form-switch marginLeft8"
                    >
                        <input
                            :id="'showCorrectAnswer-' + question.uuid"
                            v-model="question.displayAnswer"
                            type="checkbox"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            @change="toggleAnswers()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" />
                    </label>
                </div>
                <div class="inlineFlex align-items">
                    <label :for="'showAnswerChoicesStatistic-' + question.uuid" class="control-label marginBottom0 marginTop10">
                        <template v-if="testObj.others.applicationType == 'individual'"> Show Students Answers </template>
                        <template v-else>Show Teams' Answers</template>
                    </label>
                    <label
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="form-switch marginLeft8"
                    >
                        <input
                            :id="'showAnswerChoicesStatistic-' + question.uuid"
                            v-model="question.displayAnswerStatistics"
                            type="checkbox"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            @change="toggleStatistics()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" />
                    </label>
                </div>
                <!--<button class="btn btn-primary mainBtn">
            Team Selector&nbsp;<i class="fas fa-sync"></i>
          </button>-->

                <!--<button class="btn gradient btn-default mainBtn">
          <i class="fa fa-eye"></i>&nbsp;Who Have Not Answered
        </button>-->
            </div>
            <div v-else-if="question.type == 'openended'" class="flexOnly">
                <!-- <template v-if="[null].includes(question.eGallery.status)">
          <button v-if="!question.displayAnswer" class="btn btn-success mainBtn" @click="toggleAnswers()">
            Show Answers&nbsp;<i class="fa fa-eye"></i>
          </button>
          <button v-else class="btn btn-warning mainBtn" @click="toggleAnswers()">
            Hide Answers&nbsp;<i class="fa fa-eye-slash"></i>
          </button>
        </template>-->
                <template v-if="eGallerySettings.timeLimit && ['ongoing'].includes(question.eGallery.status)">
                    <div class="timer_Div darkGreen flex align-items justifyCenter">
                        <div>
                            <div class="timeText">MINUTES</div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.minutes.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.minutes.charAt(1) }}
                                </div>
                            </div>
                        </div>

                        <div class="colon">:</div>

                        <div>
                            <div class="timeText">SECONDS</div>
                            <div class="time flex">
                                <div class="number-div">
                                    {{ time.seconds.charAt(0) }}
                                </div>
                                <div class="number-div">
                                    {{ time.seconds.charAt(1) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="showRealtimeToggle">
                    <!-- <div class="switchToggle flexOnly align-items" v-if="[null,'preparing','cancelled'].includes(question.eGallery.status)&&showRealtimeToggle">
            <label class="control-label">
              <span v-if="testObj.others.applicationType=='individual'">
                REVEAL STUDENTS’ ANSWERS
              </span>
              <span v-else>
                REVEAL TEAMS’ ANSWERS
              </span>
            </label>
            <label class="switch">
              <input type="checkbox" v-model="question.displayAnswer" @change="toggleAnswers()" :disabled="(hasWritePrivilege(testObj.course)==-1) ? true:undefined">
              <span class="slider round"></span>
            </label>
          </div> -->
                    <!--<button class="btn btn-primary mainBtn" v-if="[null,'preparing','cancelled'].includes(question.eGallery.status)">
            Team Selector&nbsp;<i class="fas fa-sync"></i>
          </button>-->

                    <!--<div class="dropdown marginLeft20 viewDropdown margin0" v-if="['ongoing','ended','released'].includes(question.eGallery.status)" v-tooltip="{content: hasWritePrivilege(testObj.course)==-1?readOnlyLabel:''}">
            <button class="btn drop-down" type="button" id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="(hasWritePrivilege(testObj.course)==-1) ? true:undefined">
              <span class="marginRight10">
                More Actions
              </span>
              <span>
                <i class="fa fa-caret-down"></i>
              </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="viewDropdownMenuButton">
              <button class="btn btn-warning mainBtn" v-if="['ongoing'].includes(question.eGallery.status)" @click="pause()">
                Pause e-Gallery Walk&nbsp;<i class="fa fa-pause"></i>
              </button>
              <button class="btn btn-danger mainBtn" v-if="['ended','released'].includes(question.eGallery.status)" @click="openResetConfirmationModal()">
                Reset e-Gallery Walk&nbsp;<i class="fa fa-refresh"></i>
              </button>
              <button class="btn btn-default mainBtn" v-if="['ongoing','ended'].includes(question.eGallery.status)" @click="cancel()">
                Cancel e-Gallery Walk&nbsp;
              </button>
              
            </div>
          </div>-->

                    <div v-if="['ongoing', 'ended', 'released'].includes(question.eGallery.status)" class="cr-dropdowns marginLeft20">
                        <button
                            v-if="hasWritePrivilege(testObj.course) > 0"
                            :id="`dLabel-${question.uuid}`"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            data-target="#"
                            href="#"
                        >
                            Actions
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="['ongoing'].includes(question.eGallery.status)" aria-label="Pause e-Gallery Walk">
                                <div class="checkbox flex" @click="pause()">
                                    <label class="selection-dropdown-checked">
                                        <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause e-Gallery Walk
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ended', 'released', 'ongoing'].includes(question.eGallery.status)">
                                <div class="checkbox flex" @click="openResetConfirmationModal()">
                                    <label class="selection-dropdown-checked">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true" />
                                        Reset e-Gallery Walk
                                    </label>
                                </div>
                            </li>
                            <!-- <li
                                v-if="
                                    ['ongoing'].includes(
                                        question.eGallery.status
                                    )
                                "
                            >
                                <div
                                    class="checkbox flex"
                                    @click="openEndConfirmationModal()"
                                >
                                    <label class="selection-dropdown-checked">
                                        <i class="fas fa-stop-circle marginRight8" aria-hidden="true" />Cancel e-Gallery Walk
                                    </label>
                                </div>
                            </li> -->
                        </ul>
                    </div>

                    <div
                        v-if="
                            [null, 'preparing', 'cancelled', 'ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status) && showRealtimeToggle
                        "
                        class="cr-dropdowns marginLeft20"
                        style="font-size: 30px"
                    >
                        <button
                            v-if="hasWritePrivilege(testObj.course) > 0"
                            :id="`dLabel-${question.uuid}`"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            data-target="#"
                            href="#"
                            aria-label="View"
                        >
                            View
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="showRealtimeToggle">
                                <div class="checkbox flex">
                                    <input
                                        :id="`reveal-ans-toggle-${question.uuid}`"
                                        v-model="question.displayAnswer"
                                        type="checkbox"
                                        @change="toggleAnswers(question)"
                                    />
                                    <label :for="`reveal-ans-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" aria-hidden="true" />
                                        <span class="marginLeft8">
                                            <template v-if="testObj.others.applicationType == 'individual'"> Show Students’ Answers </template>
                                            <template v-else> Show Teams’ Answers </template>
                                        </span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="question.type == 'openended'">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-keywords-toggle-${question.uuid}`"
                                        v-model="question.displayKeywords"
                                        type="checkbox"
                                        aria-label="Show Keywords"
                                        @change="toggleKeywords()"
                                    />
                                    <label :for="`show-keywords-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" aria-hidden="true" />
                                        <span class="marginLeft8">Show Keywords</span>
                                    </label>
                                </div>
                            </li>
                            <li class="dropdown-submenu">
                                <div class="checkbox flex paddingLeft15">
                                    <label class="selection-dropdown-checked flexOnly">
                                        <span class="marginLeft8">Display Column</span>
                                        <i class="fas fa-caret-right" aria-hidden="true" />
                                    </label>
                                </div>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`one-column-toggle-${question.uuid}`"
                                                v-model="oneColumn"
                                                type="checkbox"
                                                aria-label="1 Column"
                                                @change="
                                                    twoColumns = false;
                                                    threeColumns = false;
                                                    fourColumns = false;
                                                "
                                            />
                                            <label :for="`one-column-toggle-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span class="marginLeft8">1 Column</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`two-columns-toggle-${question.uuid}`"
                                                v-model="twoColumns"
                                                type="checkbox"
                                                aria-label="2 Columns"
                                                @change="
                                                    oneColumn = false;
                                                    threeColumns = false;
                                                    fourColumns = false;
                                                "
                                            />
                                            <label :for="`two-columns-toggle-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span class="marginLeft8">2 Columns</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`three-columns-toggle-${question.uuid}`"
                                                v-model="threeColumns"
                                                type="checkbox"
                                                aria-label="3 Columns"
                                                @change="
                                                    oneColumn = false;
                                                    twoColumns = false;
                                                    fourColumns = false;
                                                "
                                            />
                                            <label :for="`three-columns-toggle-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span class="marginLeft8">3 Columns</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`four-columns-toggle-${question.uuid}`"
                                                v-model="fourColumns"
                                                type="checkbox"
                                                aria-label="4 Columns"
                                                @change="
                                                    oneColumn = false;
                                                    twoColumns = false;
                                                    threeColumns = false;
                                                "
                                            />
                                            <label :for="`four-columns-toggle-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span class="marginLeft8">4 Columns</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="divider" />
                            <li class="dropdown-submenu">
                                <div class="checkbox flex paddingLeft15">
                                    <label class="selection-dropdown-checked flexOnly">
                                        <span class="marginLeft8"> Choose answers to display </span>
                                        <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                    </label>
                                </div>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="checkbox flex" style="width: 180px">
                                            <input
                                                :id="`answers-from-a-modal-${question.uuid}`"
                                                type="checkbox"
                                                aria-label="From a modal"
                                                @click="openDisplayAnswers"
                                            />
                                            <label
                                                :for="`answers-from-a-modal-${question.uuid}`"
                                                class="selection-dropdown-checked flexOnly"
                                                style="width: fit-content"
                                            >
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span> From a modal </span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns marginLeft8" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)" class="divider" />
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-percentage-toggle-${question.uuid}`"
                                        v-model="question.displayVotes"
                                        type="checkbox"
                                        aria-label="Vote Percentage"
                                        @change="toggleVotes()"
                                    />
                                    <label :for="`show-percentage-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" aria-hidden="true" />

                                        <span class="marginLeft8">Vote Percentage</span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-comments-toggle-${question.uuid}`"
                                        v-model="question.displayComments"
                                        type="checkbox"
                                        aria-label="Comments"
                                        @change="toggleComments()"
                                    />
                                    <label :for="`show-comments-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" aria-hidden="true" />
                                        <span class="marginLeft8">Comments</span>
                                    </label>
                                </div>
                            </li>
                            <li
                                v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)"
                                class="dropdown-submenu"
                                aria-label="Display Order"
                            >
                                <div class="checkbox flex paddingLeft15">
                                    <label class="selection-dropdown-checked flexOnly">
                                        <div>
                                            <span class="marginLeft8">Display Order</span>
                                        </div>
                                        <div>
                                            <i class="fas fa-caret-right" aria-hidden="true" />
                                        </div>
                                    </label>
                                </div>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-teams-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="teams"
                                                false-value=""
                                                aria-label="Teams"
                                                @change="reorderAnswers"
                                            />
                                            <label :for="`answers-from-a-modal-teams-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span class="marginLeft8">Teams</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-votes-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="votes"
                                                false-value=""
                                                aria-label="Votes"
                                                @change="reorderAnswers"
                                            />
                                            <label :for="`answers-from-a-modal-votes-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span class="marginLeft8">Votes</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-goodAnswer-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="goodAnswer"
                                                false-value=""
                                                aria-label="good answer"
                                                @change="reorderAnswers"
                                            />
                                            <label :for="`answers-from-a-modal-goodAnswer-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span class="marginLeft8">Good Answer</span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </template>
                <!--<button class="btn btn-primary mainBtn">
        e-Gallery Walk&nbsp;<i class="fa fa-flag"></i>
        </button>-->
                <!--<button class="btn gradient btn-default mainBtn" v-if="[null,'preparing','cancelled'].includes(question.eGallery.status)">
          <i class="fa fa-eye"></i>&nbsp;Who Have Not Answered
        </button>-->
            </div>
        </div>

        <div class="hidden-md hidden-lg panel-heading">
            <div class="flex align-items">
                Question
                <question-pagination :test-obj="testObj" />
            </div>
            <div v-if="question.type != 'openended'" class="marginTop20">
                <div class="switchToggle flexOnly align-items marginRight20">
                    <label :for="'showCorrectAnswer-' + question.uuid" class="control-label marginBottom0 marginTop10"> Show Correct Answer </label>
                    <label
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="form-switch marginLeft8"
                    >
                        <input
                            :id="'showCorrectAnswer-' + question.uuid"
                            v-model="question.displayAnswer"
                            type="checkbox"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            @change="toggleAnswers()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" />
                    </label>
                </div>

                <div class="inlineFlex align-items marginTop10">
                    <label :for="'showAnswerChoicesStatistic-' + question.uuid" class="control-label marginBottom0 marginTop10">
                        <template v-if="testObj.others.applicationType == 'individual'"> Show Students Answers </template>
                        <template v-else>Show Teams' Answers</template>
                    </label>
                    <label
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="form-switch marginLeft8"
                    >
                        <input
                            :id="'showAnswerChoicesStatistic-' + question.uuid"
                            v-model="question.displayAnswerStatistics"
                            type="checkbox"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            @change="toggleStatistics()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" />
                    </label>
                </div>
            </div>

            <div v-if="question.type == 'openended' && showRealtimeToggle" class="marginTop20">
                <div>
                    <div
                        v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: !canStartEgallery
                                ? 'Please select at least two teams’ answers to enable e-Gallery Walk'
                                : '' || hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-primary mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 || !canStartEgallery || question.displayAnswer == false ? true : undefined"
                            :aria-label="
                                hasWritePrivilege(testObj.course) == -1 || !canStartEgallery || question.displayAnswer == false
                                    ? 'Initialize e-Gallery Walk Disabled'
                                    : 'Initialize e-Gallery Walk'
                            "
                            @click="prepare()"
                        >
                            Initialize e-Gallery Walk
                        </button>
                    </div>

                    <div
                        v-if="['ongoing'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-danger mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'Stop e-Gallery Walk Disabled' : 'Stop e-Gallery Walk'"
                            @click="openEndConfirmationModal()"
                        >
                            Stop e-Gallery Walk<i class="fa fa-stop marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                    <div
                        v-if="['paused'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-success mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'Resume e-Gallery Walk Disabled' : 'Resume e-Gallery Walk'"
                            @click="resume()"
                        >
                            Resume e-Gallery Walk-Gallery Walk<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                    <div
                        v-if="['ended'].includes(question.eGallery.status)"
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="btn"
                        :class="{ 'p-0-i': hasWritePrivilege(testObj.course) }"
                    >
                        <button
                            class="btn btn-success mainBtn"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'Release e-Gallery Walk Disabled' : 'Release e-Gallery Walk'"
                            @click="openReleaseConfirmationModal()"
                        >
                            Release e-Gallery Walk Results<i class="fa fa-check marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                </div>

                <div class="marginTop20">
                    <div v-if="['ongoing', 'ended', 'released'].includes(question.eGallery.status)" class="cr-dropdowns">
                        <button
                            v-if="hasWritePrivilege(testObj.course) > 0"
                            :id="`dLabel-action-${question.uuid}`"
                            role="button"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            data-target="#"
                            href="#"
                        >
                            Actions
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="['ongoing'].includes(question.eGallery.status)" aria-label="Pause e-Gallery Walk">
                                <div class="checkbox flex" @click="pause()">
                                    <label class="selection-dropdown-checked">
                                        <i class="fa fa-pause" aria-hidden="true" />
                                        <span class="marginLeft8">Pause e-Gallery Walk</span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ended', 'released', 'ongoing'].includes(question.eGallery.status)">
                                <div class="checkbox flex" @click="openResetConfirmationModal()">
                                    <label class="selection-dropdown-checked">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true" />
                                        Reset e-Gallery Walk
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div
                        v-if="
                            [null, 'preparing', 'cancelled', 'ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status) && showRealtimeToggle
                        "
                        class="cr-dropdowns marginLeft20"
                        style="font-size: 30px"
                    >
                        <button
                            v-if="hasWritePrivilege(testObj.course) > 0"
                            :id="`dLabel-view-${question.uuid}`"
                            role="button"
                            data-toggle="dropdown"
                            class="btn btn-primary"
                            data-target="#"
                            href="#"
                            aria-label="View"
                        >
                            View
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <ul class="dropdown-menu multiLevel" role="menu" aria-labelledby="dropdownMenu">
                            <li v-if="showRealtimeToggle">
                                <div class="checkbox flex">
                                    <input
                                        :id="`reveal-ans-toggle-${question.uuid}`"
                                        v-model="question.displayAnswer"
                                        type="checkbox"
                                        @change="toggleAnswers()"
                                    />
                                    <label :for="`reveal-ans-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" />
                                        <span v-if="testObj.others.applicationType == 'individual'"> Show Students’ Answers </span>
                                        <span v-else> Show Teams’ Answers </span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="question.type == 'openended'">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-keywords-toggle-${question.uuid}`"
                                        v-model="question.displayKeywords"
                                        type="checkbox"
                                        aria-label="Show Keywords"
                                        @change="toggleKeywords()"
                                    />
                                    <label :for="`show-keywords-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" aria-hidden="true" />
                                        <span> Show Keywords </span>
                                    </label>
                                </div>
                            </li>
                            <li class="divider" />
                            <li class="dropdown-submenu">
                                <div class="checkbox flex paddingLeft15">
                                    <label class="selection-dropdown-checked flexOnly">
                                        <span> Choose answers to display </span>
                                        <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                    </label>
                                </div>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="checkbox flex" style="width: 180px">
                                            <input
                                                :id="`answers-from-a-modal-${question.uuid}`"
                                                type="checkbox"
                                                aria-label="From a modal"
                                                @click="openDisplayAnswers"
                                            />
                                            <label
                                                :for="`answers-from-a-modal-${question.uuid}`"
                                                class="selection-dropdown-checked flexOnly"
                                                style="width: fit-content"
                                            >
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span> From a modal </span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns marginLeft8" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)" class="divider" />
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-percentage-toggle-${question.uuid}`"
                                        v-model="question.displayVotes"
                                        type="checkbox"
                                        aria-label="Vote Percentage"
                                        @change="toggleVotes()"
                                    />
                                    <label :for="`show-percentage-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" aria-hidden="true" />
                                        <span> Vote Percentage </span>
                                    </label>
                                </div>
                            </li>
                            <li v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)">
                                <div class="checkbox flex">
                                    <input
                                        :id="`show-comments-toggle-${question.uuid}`"
                                        v-model="question.displayComments"
                                        type="checkbox"
                                        aria-label="Comments"
                                        @change="toggleComments()"
                                    />
                                    <label :for="`show-comments-toggle-${question.uuid}`" class="selection-dropdown-checked">
                                        <i class="fa fa-check" aria-hidden="true" />
                                        <span> Comments </span>
                                    </label>
                                </div>
                            </li>
                            <li
                                v-if="['ongoing', 'paused', 'ended', 'released'].includes(question.eGallery.status)"
                                class="dropdown-submenu"
                                aria-label="Display Order"
                            >
                                <div class="checkbox flex paddingLeft15">
                                    <label class="selection-dropdown-checked flexOnly">
                                        <div>
                                            <span> Display Order </span>
                                        </div>
                                        <div>
                                            <i class="fas fa-caret-right" aria-hidden="true" />
                                        </div>
                                    </label>
                                </div>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-teams-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="teams"
                                                false-value=""
                                                aria-label="Teams"
                                                @change="reorderAnswers"
                                            />
                                            <label :for="`answers-from-a-modal-teams-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span> Teams </span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-votes-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="votes"
                                                false-value=""
                                                aria-label="Votes"
                                                @change="reorderAnswers"
                                            />
                                            <label :for="`answers-from-a-modal-votes-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span> Votes </span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="checkbox flex">
                                            <input
                                                :id="`answers-from-a-modal-goodAnswer-${question.uuid}`"
                                                v-model="reorder.sort"
                                                type="checkbox"
                                                true-value="goodAnswer"
                                                false-value=""
                                                aria-label="good answer"
                                                @change="reorderAnswers"
                                            />
                                            <label :for="`answers-from-a-modal-goodAnswer-${question.uuid}`" class="selection-dropdown-checked flexOnly">
                                                <div>
                                                    <i class="fa fa-check" aria-hidden="true" />
                                                    <span> Good Answer </span>
                                                </div>
                                                <div>
                                                    <i class="fas fa-columns" aria-hidden="true" />
                                                </div>
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-body eGallery-panel">
            <div v-if="question.type == 'openended' && !['ended'].includes(question.eGallery.status)" class="marginBottom20 question-div-bar">
                <template v-if="question.type == 'openended' && ['paused', 'ongoing', 'released'].includes(question.eGallery.status)">
                    <div class="marginTop10 flexOnly hidden-xs hidden-sm">
                        <template v-if="testObj.others.applicationType == 'team'">
                            <div v-if="question.whoHaveNotAnswered.length != 0" class="responseFont col-xs-2 maxWidth160px">
                                {{ question.whoHaveNotAnswered.length }}
                                <template v-if="question.whoHaveNotAnswered.length <= 1"> Team Has Not Voted </template>
                                <template v-else> Teams Have Not Voted </template>
                            </div>

                            <div class="discussion-buttons">
                                <template v-for="(model, model_idx) in question.whoHaveNotAnswered">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.name"
                                        >
                                            {{ truncate(model.name) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                <div class="flexOnly marginBottom5 align-items">
                                                    <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                    <span class="lineHeight08 fontBold"> Team Members </span>
                                                </div>
                                                <div class="maxHeight100px">
                                                    <ul class="marginBottom0">
                                                        <li v-for="(student, idx) in model.members">
                                                            {{ student.displayName }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <template v-if="model.members.length > 4">
                                                    <div class="blue pointer marginTop10" @click="openModalMembersInfo(model)">View More</div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-else>
                            <div v-if="question.whoHaveNotAnswered.length != 0" class="responseFont col-xs-2 maxWidth200px">
                                {{ question.whoHaveNotAnswered.length }}
                                <template v-if="question.whoHaveNotAnswered.length <= 1"> Student Has Not Voted </template>
                                <template v-else> Students Have Not Voted </template>
                            </div>

                            <div class="discussion-buttons">
                                <template v-for="(model, model_idx) in question.whoHaveNotAnswered" :key="model_idx">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.fullname"
                                        >
                                            {{ truncate(model.fullname) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <dic>
                                                <ul>
                                                    <li>
                                                        {{ model.fullname }}
                                                    </li>
                                                </ul>
                                            </dic>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>

                    <div class="marginTop10 hidden-md hidden-lg">
                        <template v-if="testObj.others.applicationType == 'team'">
                            <div v-if="question.whoHaveNotAnswered.length != 0" class="responseFont">
                                {{ question.whoHaveNotAnswered.length }}
                                <template v-if="question.whoHaveNotAnswered.length <= 1"> Team Has Not Voted </template>
                                <template v-else> Teams Have Not Voted </template>
                            </div>

                            <div class="discussion-buttons marginTop10">
                                <template v-for="(model, model_idx) in question.whoHaveNotAnswered">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.name"
                                        >
                                            {{ truncate(model.name) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                <div class="flexOnly marginBottom5 align-items">
                                                    <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                    <span class="lineHeight08 fontBold">Team Members</span>
                                                </div>
                                                <div class="maxHeight100px">
                                                    <ul class="marginBottom0">
                                                        <li v-for="(student, idx) in model.members">
                                                            {{ student.displayName }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <template v-if="model.members.length > 4">
                                                    <div class="blue pointer marginTop10" @click="openModalMembersInfo(model)">View More</div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-else>
                            <div v-if="question.whoHaveNotAnswered.length != 0" class="responseFont">
                                {{ question.whoHaveNotAnswered.length }}
                                <template v-if="question.whoHaveNotAnswered.length <= 1"> Student Has Not Voted </template>
                                <template v-else> Students Have Not Voted </template>
                            </div>

                            <div class="discussion-buttons marginTop10">
                                <template v-for="(model, model_idx) in question.whoHaveNotAnswered">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.fullname"
                                        >
                                            {{ truncate(model.fullname) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <div>
                                                <ul>
                                                    <li>
                                                        {{ model.fullname }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div v-if="![, 'scheduled', 'not started', 'completed', 'ended'].includes(testObj.status)" class="question-bar">
                        <template v-if="percentNotAnswered >= 10">
                            <div class="barProgress" :style="'width:' + percentNotAnswered + '%'">{{ percentNotAnswered }}%</div>
                        </template>
                        <template v-else>
                            <div class="col-xs-12 whiteText">{{ percentNotAnswered }}%</div>
                        </template>
                    </div>

                    <div class="marginTop10 flexOnly">
                        <template v-if="testObj.others.applicationType == 'team'">
                            <div
                                v-if="question.presentTeamsWithNoAnswer.length != 0 && ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)"
                                class="responseFont col-xs-4 maxWidth200px"
                            >
                                {{ question.presentTeamsWithNoAnswer.length }}
                                <template v-if="question.presentTeamsWithNoAnswer.length <= 1"> Team Have Not Answered </template>
                                <template v-else> Teams Have Not Answered </template>
                            </div>

                            <div v-if="['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)" class="discussion-buttons">
                                <template v-for="(model, model_idx) in question.presentTeamsWithNoAnswer">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.name"
                                        >
                                            {{ truncate(model.name) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                <div class="flexOnly marginBottom5 align-items">
                                                    <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                    <span class="lineHeight08 fontBold">Team Members</span>
                                                </div>
                                                <div class="maxHeight100px">
                                                    <ul class="marginBottom0">
                                                        <li v-for="(student, idx) in model.members">
                                                            {{ student.displayName }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <template v-if="model.members.length > 4">
                                                    <div class="blue pointer marginTop10" @click="openModalMembersInfo(model)">View More</div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-else>
                            <div
                                v-if="question.presentStudentsWithNoAnswer.length != 0 && ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)"
                                class="responseFont col-xs-4 maxWidth200px"
                            >
                                {{ question.presentStudentsWithNoAnswer.length }}
                                <template v-if="question.presentStudentsWithNoAnswer.length <= 1"> Student Have Not Answered </template>
                                <template v-else> Students Have Not Answered </template>
                            </div>

                            <div v-if="['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status)" class="discussion-buttons">
                                <template v-for="(model, model_idx) in question.presentStudentsWithNoAnswer">
                                    <div class="dropdown">
                                        <button
                                            id="popOver"
                                            class="btn gradient btn-default marginBottom10"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :aria-label="model.fullname"
                                        >
                                            {{ truncate(model.fullname) }}
                                        </button>
                                        <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                            <dic>
                                                <ul>
                                                    <li>
                                                        {{ model.fullname }}
                                                    </li>
                                                </ul>
                                            </dic>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </div>

            <div style="background: inherit; border: none">
                <template v-if="question.type == 'mcqs' || question.type == 'mcqm'">
                    <mcq
                        :test-obj="testObj"
                        :question="question"
                        :options="options"
                        :show-realtime-toggle="showRealtimeToggle"
                        :activities-status="activitiesStatus"
                    />
                </template>
                <template v-if="question.type == 'openended'">
                    <openended
                        :test-obj="testObj"
                        :question="question"
                        :show-realtime-toggle="showRealtimeToggle"
                        :one-column="oneColumn"
                        :two-columns="twoColumns"
                        :three-columns="threeColumns"
                        :four-columns="fourColumns"
                        @save-grades="saveGrades"
                    />
                </template>
                <template v-if="question.type == 'vsa'">
                    <vsa :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
                <template v-if="question.type == 'sequence'">
                    <sequence :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
                <template v-if="question.type == 'matching' && question.options.matchingType == 'one-to-one'">
                    <matching :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
                <template v-if="question.type == 'matching' && question.options.matchingType == 'one-to-many'">
                    <categorize :test-obj="testObj" :question="question" :show-realtime-toggle="showRealtimeToggle" />
                </template>
            </div>
        </div>
        <div
            v-if="question.type == 'openended'"
            :id="'eGalleryProcessStartModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryProcessStartModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content night-mode">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryProcessStartModal' + question.uuid + '-title'" class="modal-title">Initalize e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body">
                        <div class="fontItalic">This is hidden from the Teams’/Students’ view.</div>

                        <div class="flexOnly align-items">
                            <div class="with-border width100">
                                <label class="control-label">
                                    Comment Requirements
                                    <span class="important">*</span>
                                </label>

                                <div class="marginBottom20">
                                    <template
                                        v-for="item in [
                                            {
                                                description: 'No requirement',
                                                value: 0,
                                            },
                                            {
                                                description: 'Teams must provide a comment for answers they voted for only',
                                                value: 1,
                                            },
                                            {
                                                description: 'Teams must provide a comment for all answers.',
                                                value: 3,
                                            },
                                        ]"
                                        :key="'eGallery_' + item.value"
                                    >
                                        <div
                                            class="radioField flex paddingLeft15"
                                            :class="{
                                                marginTop10: item.value != 0,
                                            }"
                                        >
                                            <input
                                                :id="question.uuid + '-commentRadio' + item.value"
                                                v-model="eGallerySettings.commentRadio"
                                                type="radio"
                                                :value="item.value"
                                                :aria-label="item.description"
                                                @change="toggleComment"
                                            />
                                            <label class="marginLeft10" :for="question.uuid + '-commentRadio' + item.value">
                                                {{ item.description }}
                                            </label>
                                        </div>
                                    </template>
                                </div>

                                <label class="control-label">
                                    Duration Requirements
                                    <span class="important">*</span>
                                </label>
                                <div class="checkboxField">
                                    <input
                                        :id="question.uuid + '-timeLimit'"
                                        v-model="eGallerySettings.timeLimit"
                                        type="checkbox"
                                        aria-label="Set a time-limit for e-Gallery walk"
                                    />
                                    <label class="marginLeft10" :for="question.uuid + '-timeLimit'"> Set a time-limit for e-Gallery walk </label>
                                </div>
                                <div v-if="eGallerySettings.timeLimit">
                                    <label class="marginRight10">Time Limit in Minutes</label>
                                    <div class="numberInput">
                                        <input v-model="eGallerySettings.timeLimitInMinutes" type="number" class="form-control" @change="updatedMins" />
                                        <span class="quantity-nav">
                                            <div
                                                class="quantity-button quantity-up"
                                                :class="{
                                                    disabled: eGallerySettings.timeLimitInMinutes >= 60,
                                                }"
                                                @click="eGallerySettings.timeLimitInMinutes < 60 ? eGallerySettings.timeLimitInMinutes++ : ''"
                                            >
                                                <i class="fas fa-plus" aria-hidden="true" />
                                            </div>
                                            <div
                                                class="quantity-button quantity-down"
                                                :class="{
                                                    disabled: eGallerySettings.timeLimitInMinutes <= 1,
                                                }"
                                                @click="eGallerySettings.timeLimitInMinutes > 0 ? eGallerySettings.timeLimitInMinutes-- : ''"
                                            >
                                                <i class="fas fa-minus" aria-hidden="true" />
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button class="btn btn-success mainBtn" aria-label="Start e-Gallery Walk" data-dismiss="modal" @click.prevent="start()">
                                Start e-Gallery Walk
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" />
            </div>
        </div>
        <div
            :id="'eGalleryResetModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryResetModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 :id="'eGalleryResetModal' + question.uuid + '-title'" class="modal-title">Reset e-Gallery</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div>e-Gallery Walk will be reset. All students votes will be cleared.</div>
                            <div>Are you sure?</div>
                        </div>
                        <div class="flex marginTop30">
                            <button
                                id="modal-logout-btn"
                                class="btn btn-success mainBtn"
                                data-dismiss="modal"
                                aria-label="Yes, Reset"
                                @click.prevent="cancel()"
                            >
                                Yes, Reset
                            </button>
                            <button id="modal-dismiss-btn" class="btn btn-default mainBtn" data-dismiss="modal" aria-label="No, Cancel">No, Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'membersModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'membersModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'membersModal' + question.uuid + '-title'" class="modal-title">View</h2>
                    </div>
                    <div class="modal-body previewModelTeam">
                        <div class="flexOnly marginBottom5 align-items">
                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                            <span class="lineHeight08 fontBold">Team Members</span>
                        </div>
                        <div>
                            <ul v-for="(student, idx) in previewModel.team">
                                <li>{{ student.displayName }}</li>
                            </ul>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryStartModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryStartModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content night-mode">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryStartModal' + question.uuid + '-title'" class="modal-title">Start e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div>You have selected the following team’s answers for the e-Gallery.</div>
                            <div v-for="(answer, answer_idx) in question.answers" class="with-top-gaps">
                                <template v-if="question.answers[answer_idx].isSelected == true">
                                    <button
                                        class="btn btn-default subBtn cursor-norm"
                                        :aria-label="
                                            testObj.others.applicationType == 'team'
                                                ? question.answers[answer_idx].team.name
                                                : question.answers[answer_idx].student.fullname
                                        "
                                    >
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            {{ question.answers[answer_idx].team.name }}
                                        </template>
                                        <template v-else>
                                            {{ question.answers[answer_idx].student.fullname }}
                                        </template>
                                    </button>
                                </template>
                            </div>
                        </div>
                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button id="modal-logout-btn" class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Start" @click.prevent="start()">
                                Start
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryEndModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryEndModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryEndModal' + question.uuid + '-title'" class="modal-title">Stop e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div>You are about to stop the e-Gallery Walk</div>
                            <div>Teams/Students will no longer be able to vote and add comments.</div>
                            <div>Are you sure?</div>
                        </div>
                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default" data-dismiss="modal" aria-label="No, Cancel">No, Cancel</button>
                            <button id="modal-logout-btn" class="btn btn-danger" data-dismiss="modal" @click.prevent="end()">Yes, Stop e-Gallery Walk</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryReleaseModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryReleaseModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 :id="'eGalleryReleaseModal' + question.uuid + '-title'" class="modal-title">Release e-Gallery Walk Results</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div>You are about to release the results of the e-Gallery walk</div>
                            <div>Teams/Students will be able to see the vote results and comments from other teams and students</div>
                            <div>Are you sure?</div>
                        </div>
                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default mainBtn" data-dismiss="modal" aria-label="No, Cancel">No, Cancel</button>
                            <button
                                id="modal-logout-btn"
                                class="btn btn-success mainBtn"
                                data-dismiss="modal"
                                aria-label="Yes, Release e-Gallery Walk Results"
                                @click.prevent="release()"
                            >
                                Yes, Release e-Gallery Walk Results
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'displayAnswers' + question.uuid"
            class="modal displayAnswers"
            data-backdrop="static"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'displayAnswers' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 :id="'displayAnswers' + question.uuid + '-title'" class="modal-title">Answers</h2>
                        <button id="modal-dismiss-btn" class="btn btn-default subBtn" data-dismiss="modal" aria-label="Close" style="color: #222">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>
                    </div>

                    <div class="modal-body">
                        <template v-for="(answer, answer_idx) in question.answers">
                            <div class="inline-flex">
                                <template v-if="testObj.others.applicationType == 'team'">
                                    <button class="btn gradient btn-default" :aria-label="answer.team.name">
                                        {{ answer.team.name }}
                                    </button>
                                </template>
                                <template v-else>
                                    <button class="btn gradient btn-default" :aria-label="answer.student.fullname">{{ answer.student.fullname }}&nbsp;</button>
                                </template>
                                <span v-html="truncate(answer.answer, 50)" />
                                <div class="checkboxField" style="display: inline; float: right">
                                    <input
                                        v-model="question.answers[answer_idx].isSelected"
                                        type="checkbox"
                                        :aria-label="testObj.others.applicationType == 'team' ? answer.team.name : answer.student.fullname"
                                    />
                                </div>
                            </div>
                        </template>

                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button class="btn btn-success" data-dismiss="modal" aria-label="Ok" @click.prevent="saveDisplayAnswers">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="question.uuid + '-enlarge-image-modal'"
            class="modal modal-manual"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'enlargeImageModal-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'enlargeImageModal-' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />Enlarged Image
                        </h2>
                    </div>
                    <div class="modal-body padding0">
                        <img :key="srcValue" :src="srcValue" width="100%" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryCancelModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCancelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryCancelModal' + question.uuid + '-title'" class="modal-title">Cancel e-Gallery Walk</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div>You are about to cancel the e-Gallery Walk</div>
                            <div>Teams/Students will no longer be able to vote and add comments.</div>
                            <div>Are you sure?</div>
                        </div>
                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default" data-dismiss="modal">No, Cancel</button>
                            <button id="modal-logout-btn" class="btn btn-danger" data-dismiss="modal" @click.prevent="cancel()">
                                Yes, Cancel e-Gallery Walk
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'facultyNotesModal-' + facultyNoteQuestion.uuid"
            class="modal default-modal facultyNotesModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="facultyNotesModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>

                        <h2 id="facultyNotesModal-title" class="modal-title">
                            Question {{ getQuestionNo(facultyNoteQuestion.group - 1, facultyNoteQuestion.order - 1) }} Notes
                        </h2>
                    </div>

                    <div class="modal-body">
                        <p class="whiteSpaceBreakSpacesText">
                            {{ facultyNoteQuestion.remarks }}
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ChangeQuestionCopyWritingComponent ref="changeQuestionCopyWritingComponent" />
</template>

<script>
import { testMixins } from './../../../../../../../../../../../../mixins/test';
import KrAuth from '../../../../../../../../../../../../components/auth/auth';
import ChangeQuestionCopyWritingComponent from '../../../../../../../actions/changeQuestionCopyWriting';

export default {
    components: {
        ChangeQuestionCopyWritingComponent,
    },
    mixins: [testMixins],
    props: ['testObj', 'question', 'question_group_idx', 'question_idx', 'showRealtimeToggle', 'activitiesStatus'],
    data() {
        let auth = new KrAuth();
        return {
            component_done_loading: false,
            options: {
                showCorrectAnswers: this.showRealtimeToggle != null ? !this.showRealtimeToggle : false,
            },
            eGallerySettings: {
                step: 0,
                answerSelectionForVoting: 'all',
                votingMethod: 'best',
                teamsMustCommentForVotedAnswersOnly: false,
                teamsMustCommentForAllAnswers: false,
                timeLimit: false,
                timeLimitInMinutes: 30,
                commentRadio: 0, //1 = voted answer, 3 = all answer
            },
            tempQuestion: {},
            fromDashboard: true,
            oneColumn: true,
            twoColumns: false,
            threeColumns: false,
            fourColumns: false,
            previewModel: { type: 'team', student: {}, team: {} },
            auth: auth,
            readOnlyLabel: 'You have read-only access',
            reorder: { sort: 'goodAnswer', order: 'asc' },
            isReordered: false,
            timer: null,
            time: { minutes: '00', seconds: '00' },
            min: 1,
            srcValue: '',
            facultyNoteQuestion: {},
        };
    },
    computed: {
        canStartEgallery() {
            if (this.question.type == 'openended') {
                let count = 0;
                for (var i = 0; i < this.question.answers.length; i++) {
                    if (this.question.answers[i].isSelected) {
                        count++;
                    }
                }
                if (count > 1) {
                    // if (
                    //   (this.question.eGallery.votingMethod == "rank" &&
                    //     this.question.eGallery.rankingValue) ||
                    //   this.question.eGallery.votingMethod == "best"
                    // ) {
                    //   return true;
                    // }
                    return true;
                }
            }
            return false;
        },
        percentNotAnswered() {
            if (this.testObj.others.applicationType != 'team') {
                let present = this.question.countPresentStudents;
                if (present == 0) {
                    return 0;
                }
                return Math.round((this.question.presentStudentsWithNoAnswer.length / present) * 100);
            } else {
                let present = this.question.countPresentTeams;
                if (present == 0) {
                    return 0;
                }
                return Math.round((this.question.presentTeamsWithNoAnswer.length / present) * 100);
            }
            /*
        if(this.question.type=='mcqs'||this.question.type=='mcqm'){

        }else{
          let present  = this.question.answers.length;
          if(present == 0) {
            return null;
          }
          let unanswered = 0;
          for (var i = 0;i<this.question.answers.length;i++){
            if(!this.question.answers[i].answer){
              unanswered++;
            }
          }
          return Math.round((unanswered / present)*100);
        }*/
        },
        percentNotVoted() {
            let present = this.question.answers.length;
            if (present == 0) {
                return 0;
            }

            let notVoted = this.question.whoHaveNotAnswered.length;
            return Math.round((notVoted / present) * 100);
        },
    },
    watch: {
        question: {
            handler: function () {
                if (this.testObj.type == 'application' && this.question.type == 'openended') {
                    this.question.eGallery.votingMethod = this.question.eGallery.votingMethod ? this.question.eGallery.votingMethod : 'best';
                    this.question.eGallery.rankingValue = this.question.eGallery.rankingValue ? this.question.eGallery.rankingValue : 1;
                    for (var i = 0; i < this.question.answers.length; i++) {
                        if (this.question.answers[i].isGoodAnswer == null) {
                            this.question.answers[i].isGoodAnswer = false;
                            /*Vue.set(
                                this.question.answers[i],
                                '.isGoodAnswer',
                                false
                            );*/
                        }
                        if (this.question.answers[i].isSelected == null) {
                            this.question.answers[i].isSelected = true;
                            /*Vue.set(
                                this.question.answers[i],
                                '.isSelected',
                                true
                            );*/
                        }
                        if (this.question.answers[i]._gradeSaved === undefined) {
                            this.question.answers[i]._gradeSaved = false;
                            /*Vue.set(
                                this.question.answers[i],
                                '_gradeSaved',
                                false
                            );*/
                        }
                        if (this.question.answers[i]._narrativeSaved === undefined) {
                            this.question.answers[i]._narrativeSaved = false;
                            /*Vue.set(
                                this.question.answers[i],
                                '_narrativeSaved',
                                false
                            );*/
                        }
                    }
                    if (!this.isReordered) {
                        this.reorderAnswers();
                    }
                    this.isReordered = !this.isReordered;
                }

                if (
                    this.question.type == 'openended' &&
                    ['ongoing'].includes(this.question.eGallery.status) &&
                    this.question.eGalleryWalkSettings.timeLimitInMinutes
                ) {
                    this.initTimer();
                }
            },
            deep: true,
        },
    },
    created() {
        if (this.testObj.type == 'application' && this.question.type == 'openended') {
            this.question.eGallery.votingMethod = this.question.eGallery.votingMethod ? this.question.eGallery.votingMethod : 'best';
        }
        this.component_done_loading = true;
        var that = this;
        if (this.question.type == 'openended' && this.$route.name != 'activities.tests.dashboard') {
            Vue.nextTick(function () {
                that.reorderAnswers();
            });
            Events.listen('e-gallery-reorder-answers', function () {
                that.reorderAnswers();
            });
            /*Events.listen('toggle',function(){
           if(that.question.eGallery.status=='released'){
              that.question.eGallery.status = 'preparing';
           }else{
              that.question.eGallery.status = 'released';
           }
          });*/
        }
        Vue.nextTick(function () {
            $('#' + that.question.uuid + '-enlarge-image-modal').draggable();
        });
        // document.addEventListener("beforeunload", this.end());
    },
    mounted() {
        window.addEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });

        if (['ongoing'].includes(_.get(this.question, 'eGallery.status'))) {
            this.eGallerySettings.timeLimit = this.question.eGalleryWalkSettings.hasTimeLimit;
        }
    },
    methods: {
        toggleComment() {
            if (this.eGallerySettings.commentRadio == 0) {
                this.eGallerySettings.teamsMustCommentForVotedAnswersOnly = false;
                this.eGallerySettings.teamsMustCommentForAllAnswers = false;
            } else if (this.eGallerySettings.commentRadio == 1) {
                this.eGallerySettings.teamsMustCommentForVotedAnswersOnly = true;
                this.eGallerySettings.teamsMustCommentForAllAnswers = false;
            } else if (this.eGallerySettings.commentRadio == 3) {
                this.eGallerySettings.teamsMustCommentForVotedAnswersOnly = false;
                this.eGallerySettings.teamsMustCommentForAllAnswers = true;
            }
        },
        toggleKeywords() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-keywords', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question keywords are now' + (that.question.displayKeywords ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayKeywords = !that.question.displayKeywords;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to toggle question answers at this moment, please try again later',
                    });
                });
        },
        toggleComments() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-comments', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question comments are now' + (that.question.displayComments ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayComments = !that.question.displayComments;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to toggle question comments at this moment, please try again later',
                    });
                });
        },
        toggleVotes() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-votes', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question keywords are now' + (that.question.displayVotes ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayVotes = !that.question.displayVotes;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to toggle question votes at this moment, please try again later',
                    });
                });
        },
        reorderAnswers() {
            if (this.question.type != 'openended' || !['preparing', 'paused', 'ongoing', 'ended', 'released'].includes(this.question.eGallery.status)) {
                return;
            }
            for (var i = 0; i < this.question.answers.length; i++) {
                this.question.answers[i]._name = this.question.answers[i].team ? this.question.answers[i].team.name : this.question.answers[i].student.fullname;
                this.question.answers[i]._voteCount = this.question.answers[i].votes.length;
            }

            this.natSort(this.question.answers, '_name');

            for (var i = 0; i < this.question.answers.length; i++) {
                this.question.answers[i]._natSortIndex = i;
            }

            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.question.answers[i].isGoodAnswer == 1) {
                    this.question.answers[i]._goodAnswer = this.question.answers[i].team
                        ? this.question.answers[i].team.name
                        : this.question.answers[i].student.fullname;
                }
            }

            if (this.reorder.sort == 'teams') {
                return;
            } else if (this.reorder.sort == 'votes') {
                this.question.answers = _.orderBy(this.question.answers, ['_voteCount', '_natSortIndex'], ['desc', 'asc']);
            } else if (this.reorder.sort == 'goodAnswer') {
                this.question.answers = _.orderBy(this.question.answers, ['_goodAnswer', '_natSortIndex'], ['asc']);
            } else {
                this.question.answers = _.orderBy(
                    this.question.answers,
                    [
                        function (object) {
                            return new Date(object.dateCompleted);
                        },
                    ],
                    ['desc']
                );
            }
        },
        saveDisplayAnswers() {
            var that = this;
            let data = {
                activityQuestionUuid: this.question.uuid,
                answers: [],
            };
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid) {
                    continue;
                }
                data.answers.push({
                    uuid: answer.uuid,
                    isSelected: answer.isSelected ? true : false,
                });
            }
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/select-answers', data)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Display answers have been saved',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to save display answers at this moment, please try again later',
                    });
                });
        },
        openDisplayAnswers() {
            $('#displayAnswers' + this.question.uuid).modal('show');
        },
        openReleaseConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryReleaseModal' + this.question.uuid).modal('show');
        },
        openEndConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryEndModal' + this.question.uuid).modal('show');
        },
        openStartConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryStartModal' + this.question.uuid).modal('show');
        },
        openCancelConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryCancelModal' + this.question.uuid).modal('show');
        },
        initializeEGalleryWalkData() {
            this.question.eGallery.votingMethod = this.eGallerySettings.votingMethod;
            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.eGallerySettings.answerSelectionForVoting == 'all') {
                    this.question.answers[i].isSelected = true;
                    //Vue.set(this.question.answers[i], 'isSelected', true);
                }
                if (this.eGallerySettings.answerSelectionForVoting == 'good') {
                    if (this.question.answers[i].isGoodAnswer) {
                        this.question.answers[i].isSelected = true;
                        //Vue.set(this.question.answers[i], 'isSelected', true);
                    } else {
                        this.question.answers[i].isSelected = false;
                        //Vue.set(this.question.answers[i], 'isSelected', false);
                    }
                }
            }
            this.eGallerySettings.step++;
        },
        openResetConfirmationModal() {
            //this.openStartPasswordModal();
            //return;
            $('#eGalleryResetModal' + this.question.uuid).modal('show');
        },
        initWalk() {
            let data = {};
            data.activityQuestionUuid = this.question.uuid;
            data.votingMethod = this.question.eGallery.votingMethod;
            if (data.votingMethod == 'rank') {
                data.rankingValue = this.question.eGallery.rankingValue;
            }

            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid) {
                    continue;
                }
                if (this.question.eGallery.votingMethod == 'best') {
                    answer.votes = [];
                    answer.comments = [];
                } else {
                    answer.ranks = [];
                    answer.comments = [];
                    for (var i = 0; i < this.question.eGallery.rankingValue; i++) {
                        answer.ranks.push([]);
                    }
                }
            }
            data.answers = [];
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid) {
                    continue;
                }
                data.answers.push({
                    uuid: answer.uuid,
                    isSelected: answer.isSelected ? true : false,
                    isGoodAnswer: answer.isGoodAnswer ? true : false,
                });
            }
            data.eGalleryWalkSettings = {
                teamsMustCommentForVotedAnswersOnly: this.eGallerySettings.teamsMustCommentForVotedAnswersOnly,
                teamsMustCommentForAllAnswers: this.eGallerySettings.teamsMustCommentForAllAnswers,
                timeLimitInMinutes: this.eGallerySettings.timeLimit ? this.eGallerySettings.timeLimitInMinutes : null,
                hasTimeLimit: this.eGallerySettings.timeLimit,
            };
            return data;
        },
        toggleAnswers(question) {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-answer', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question answers are now' + (that.question.displayAnswer ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayAnswer = !that.question.displayAnswer;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question answers at this moment, please try again later',
                    });
                });
        },
        toggleStatistics() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-answer-statistics', { activityQuestionUuid: this.question.uuid })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question statistics are now' + (that.question.displayAnswerStatistics ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayAnswerStatistics = !that.question.displayAnswerStatistics;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question statistics at this moment, please try again later',
                    });
                });
        },
        saveGrades() {
            var that = this;
            let grades = {
                activityQuestionUuid: this.question.uuid,
                answers: [],
            };
            for (var i = 0; i < this.question.answers.length; i++) {
                let grade = this.question.answers[i].applicationGrade != null ? this.question.answers[i].applicationGrade : 0;
                grades.answers.push({
                    uuid: this.question.answers[i].uuid,
                    grade: grade,
                });
            }
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/save', grades)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Grades have been saved',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to save grades at this moment, please try again later',
                    });
                });
        },
        prepare() {
            var that = this;
            var questionUuid = this.question.uuid;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/prepare', {
                    activityQuestionUuid: questionUuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'preparing';
                    //that.eGallerySettings.step = 1;
                    $('#eGalleryProcessStartModal' + questionUuid).modal('show');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk is now being prepared',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to prepare eGallery walk',
                    });
                });
        },
        start() {
            //this.eGallerySettings.step = 0;
            var that = this;
            let data = {};
            data = this.initWalk();
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/start', data)
                .then(function (response) {
                    that.question.eGallery.status = 'ongoing';
                    that.question.eGalleryWalkSettings = {
                        startTime: response.data.data.startTime,
                        timeLimitInMinutes: response.data.data.timeLimitInMinutes,
                    };
                    /*that.$set(that.question, 'eGalleryWalkSettings', {
                        startTime: response.data.data.startTime,
                        timeLimitInMinutes: response.data.data.timeLimitInMinutes,
                    });*/
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been started',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to start eGallery walk at this moment, please try again later',
                    });
                });
        },
        pause() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/pause', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'paused';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been paused',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to pause eGallery walk at this moment, please try again later',
                    });
                });
        },
        resume() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/resume', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been resumed',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to resume eGallery walk at this moment, please try again later',
                    });
                });
        },
        restart() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/restart', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'ongoing';
                    //clear
                    that.initWalk();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been restarted',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to restart eGallery walk at this moment, please try again later',
                    });
                });
        },
        end() {
            this.stopTimer();

            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/end', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'ended';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been ended',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to end eGallery walk at this moment, please try again later',
                    });
                });
        },
        cancel() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/cancel', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'cancelled';
                    for (var i = 0; i < that.question.answers.length; i++) {
                        that.question.answers[i].isSelected = true;
                    }
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been canceled',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to cancel eGallery walk at this moment, please try again later',
                    });
                });
        },
        release() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/release', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.question.eGallery.status = 'released';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'eGallery walk has been released',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to release eGallery walk at this moment, please try again later',
                    });
                });
        },
        openModalMembersInfo(model, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = model.members;
            }

            $('#membersModal' + this.question.uuid).modal('show');

            $('#membersModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = { type: 'team', student: {}, team: {} };
            });
        },
        truncate(str, characters = 10) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        initTimer() {
            var that = this;
            this.stopTimer();

            var startTime = this.convertToReadableDate(_.get(this.question.eGalleryWalkSettings, 'startTime', '')).dateObj;

            var endTime = startTime.add(this.question.eGalleryWalkSettings.timeLimitInMinutes, 'minutes');
            this.timer = setInterval(function (startTime) {
                var now = startTime;
                var distance = moment.duration(endTime.diff(now));
                if (distance > 0) {
                    that.time.minutes = ('0' + (distance.minutes() + distance.hours() * 60)).slice(-2);
                    that.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    // that.time.minutes = "00";
                    // that.time.seconds = "00";
                    that.end();
                }
            }, 1000);
        },
        updatedMins() {
            if (this.eGallerySettings.timeLimitInMinutes < this.min) {
                this.eGallerySettings.timeLimitInMinutes = this.min;
            }
        },
        openEnlargeImageModal(src, id) {
            if (id == this.question.uuid) {
                $('#' + this.question.uuid + '-enlarge-image-modal').modal('show');

                this.srcValue = src;

                $('#' + this.question.uuid + '-enlarge-image-modal').resizable({
                    handles: 'ne, se, sw, nw',
                });
            }
        },
        openFacultyNotesModal(question) {
            this.facultyNoteQuestion = question;

            this.$nextTick(() => {
                $('#facultyNotesModal-' + this.facultyNoteQuestion.uuid).modal('show');
            });
        },
    },
    components: {
        mcq: require(`./partials/mcq.vue`).default,
        openended: require(`./partials/openended.vue`).default,
        vsa: require(`./partials/vsa.vue`).default,
        sequence: require(`./partials/sequence.vue`).default,
        matching: require(`./partials/matching.vue`).default,
        categorize: require(`./partials/categorize.vue`).default,
        'question-pagination': require(`./../../question-pagination.vue`).default,
    },
    beforeUnmount() {
        window.removeEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });
        Events.off('e-gallery-reorder-answers');
        this.stopTimer();
    },
};
</script>
<style lang="scss" scoped>
.dropdown-menu {
    margin-bottom: 0;
}
#eGalleryResetModal .modal-body .row:last-child {
    margin-top: 30px;
}

#eGalleryResetModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#eGalleryResetModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.responseFont {
    margin-top: 8px;
    margin-right: 10px;
    font-weight: bold;
}

.with-border {
    border: 1px solid #d8d8d8;
    padding: 10px 25px;
    border-radius: 5px;
}

.with-border .flexRadio {
    margin-left: 15px;
    margin-top: 10px;
}

.with-border .flexRadio:first-child {
    margin-top: 0;
}

.steps-circle {
    background: #cfe2f3;
    font-weight: bold;
    border-radius: 50%;
    min-width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px;
    margin-right: 20px;
}

.steps-circle:after {
    content: '';
    position: absolute;
    width: 2px;
    height: calc(100% - 30rem);
    left: 3.4rem;
    top: 17rem;
    background: #495060;
}

.presentation-mode-cr .eGalleryQuestionGroup .panel-heading {
    background: #fff;
}

.cr-dropdowns {
    position: relative;
    display: inline-flex;
    align-items: center;

    .dropdown-menu {
        padding: 0;
        background: #495060;
        border: none;
        left: inherit;
        right: 0;
        text-transform: none;
    }

    .btn-primary,
    .btn-primary:visited {
        background: none;
        color: #0071be;
        box-shadow: none;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu > .dropdown-menu {
        top: 0;
        right: 100%;
        border-radius: 6px;
        min-width: 172px;
    }

    .dropdown-submenu:hover > .dropdown-menu {
        display: block;
    }

    .dropdown-submenu > a:after {
        display: block;
        content: ' ';
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -10px;
    }

    .dropdown-submenu:hover > a:after {
        border-left-color: #fff;
    }

    .dropdown-submenu.pull-left {
        float: none;
    }

    .dropdown-submenu.pull-left > .dropdown-menu {
        left: -100%;
        margin-left: 10px;
        -webkit-border-radius: 6px 0 6px 6px;
        -moz-border-radius: 6px 0 6px 6px;
        border-radius: 6px 0 6px 6px;
    }

    .checkbox {
        margin: inherit;
        input[type='checkbox'] {
            margin: 0;
            position: inherit;

            & ~ label {
                i:first-child {
                    color: transparent;
                }

                div:first-child {
                    width: calc(100% - 16px);
                }
            }

            &:checked ~ label i:first-child {
                color: #fff;
            }
        }

        label {
            min-height: inherit;
            padding: 10px;
            margin-left: 0;
            white-space: nowrap;
        }
    }

    li > div:hover {
        background: rgba(255, 163, 0, 0.5);
    }

    .dropdown-submenu input[type='checkbox'] ~ label div:last-child {
        width: 16px;
    }

    .dropdown-submenu input[type='checkbox'] ~ label div:last-child i {
        color: #fff;
    }
    .dropdown-submenu > div > label {
        min-width: 180px;
        justify-content: space-between;
        padding-right: 10px;
    }

    .selection-dropdown-checked {
        margin-left: 10px;
        font-size: 1rem;
        color: #fff;
        width: 100%;
    }

    .dropdown-menu .divider {
        margin: 0;
    }
}

@media (max-width: 992px) {
    .cr-dropdowns {
        &.marginLeft20 {
            margin-left: 0;
        }

        .dropdown-menu {
            left: 0;
            width: fit-content;
        }
    }
}

/* .cr-dropdowns .dropdown-menu.multi-level {
  padding: 5px 0;
} */

.margin-left-25px {
    margin-left: 25px;
}

@media (min-width: 992px) {
    .eGalleryQuestionGroup .panel {
        box-shadow: none;
    }
}

.night-mode .panel-body {
    background: #273142;
    color: #fff;
}

.numberInput {
    width: 58px;
    position: relative;

    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        color: #222;
        z-index: 4;
        height: 100%;
        max-height: 100%;
    }

    .quantity-nav .disabled {
        color: #dfe3e9;
        cursor: not-allowed;
    }

    .quantity-button {
        min-height: 18px;
        height: 50%;
        display: flex;
        align-items: center;
        width: 22px;
        justify-content: center;
        background-color: white !important;
        border: 1px solid #717171 !important;
    }

    .quantity-button:last-child {
        border-top: 1px solid #717171 !important;
    }
}

.timer_Div {
    .time .number-div {
        background: #fff;
        padding: 6px;
        border-radius: 3px;
        font-size: 28px;
        font-weight: bold;
        line-height: 0.8;
    }

    .time .number-div:last-child {
        margin-left: 5px;
    }

    .colon {
        color: #fff;
        padding: 0 10px;
    }

    .timeText {
        font-size: 1rem;
        margin-bottom: 5px;
    }
}

.radioField {
    label {
        width: calc(100% - 20px);
        line-height: 1.2;
    }

    input {
        display: block;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #222;
        background: #fff;
        margin-right: 5px;

        &:hover {
            border: 2px solid #0071be;
        }

        &:checked {
            border-color: #0071be;
            box-shadow: inset 0 0 0 3px #fff;
            background: #0071be;

            &:before {
                content: inherit;
            }
        }
    }
}
</style>
