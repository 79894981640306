<template>
    <div>
        <transition name="slide-fade" mode="out-in">
            <div class="container body">
                <div v-if="auth.isImpersonation()" class="impersonate-banner-container">
                    <div class="top_banner_message_placeholder_impersonate" @load="updateTopNavHeightImpersonate"></div>
                    <div ref="topNavHeightImpersonate" class="alert alert-secondary borderRadius0 textAlignCenter top_banner_message" role="alert">
                        You are currently in Technical Support Mode ({{ auth.user().displayName }}).
                        <a class="marginRight5" href="#" @click.prevent="logout"> Exit </a>
                    </div>
                </div>
                <div v-if="showCommunityBanner" class="community-banner-container">
                    <div class="top_banner_message_placeholder_community" @load="updateTopNavHeightCommunity"></div>
                    <div ref="topNavHeightCommunity" class="alert alert-secondary borderRadius0 textAlignCenter top_banner_message_community" role="alert">
                        <a href="https://community.intedashboard.com/" target="_blank">
                            <b>
                                <u>InteDashboard TBL Community</u>
                            </b>
                        </a>
                        Connect with a global network of TBL educators and maximise your TBL knowledge.
                        <a href="https://community.intedashboard.com/" target="_blank">
                            <button class="btn-outline-primary">Sign up now!</button>
                        </a>

                        <button class="floatRight marginTop2 whiteText" aria-label="Close banner" @click="closeCommunityBanner">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <div style="clear: both" />
                    </div>
                </div>
                <div class="main_container">
                    <template v-if="false">
                        <div class="top_banner_message_placeholder" @load="updateTopNavHeight"></div>
                        <div ref="topNavHeight" class="alert alert-secondary borderRadius0 textAlignCenter top_banner_message" role="alert">
                            <i class="fa-solid fa-person-circle-exclamation marginRight8" aria-hidden="true"></i>
                            Help us improve.
                            <a class="marginRight5" href="https://share.hsforms.com/1eEjiID-wS_uOWIs9iuCoeg3oi5r" target="_blank">
                                Please share your feedback here
                            </a>
                            before 30 Nov 2023.
                        </div>
                    </template>
                    <sidebar class="hidden-xs hidden-sm" />
                    <topbar />
                    <div class="right_col">
                        <div v-if="false" class="payment-alert sticky marginBottom20">
                            <router-link
                                :to="{ name: 'payments' }"
                                class="alert alert-danger flexSpaceBetween align-items"
                                role="alert"
                                style="padding-left: 10px"
                            >
                                <div class="flexOnly">
                                    <div><i class="fas fa-bell"></i>&nbsp;</div>
                                    <div v-if="paidOffset > 0">Payment has been overdue for {{ abs(paidOffset) }} days</div>
                                    <div v-else-if="paidOffset < 0">You have {{ abs(paidOffset) }} days to complete payment</div>
                                    <div v-else>Payment is due today, please settle as soon as possible</div>
                                </div>
                                <div class="alignRight">
                                    <b>Make Payment</b>
                                </div>
                            </router-link>
                        </div>
                        <div class="paddingLeft20 paddingRight20">
                            <div v-if="notPaid && !isExpired" class="alert alert-danger flex align-items marginTop20 darkText">
                                <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true" />
                                <template v-if="paidOffset > 0"> ALERT: Payment has been overdue for </template>
                                <template v-else> ALERT: Your free trial expires in </template>
                                {{ abs(paidOffset) }} day(s). &nbsp;
                                <router-link :to="{ name: 'payments' }"> Please click here to complete your payment. </router-link>
                            </div>

                            <div v-if="isExpired" class="alert alert-danger flex align-items marginTop20 darkText">
                                <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true" />
                                ALERT: Your account has expired already.&nbsp;
                                <router-link :to="{ name: 'payments' }"> Please click here to renew.</router-link>
                            </div>
                        </div>
                        <transition name="slide-fade" mode="out-in">
                            <router-view />
                        </transition>
                    </div>
                </div>
                <foot />
            </div>
        </transition>

        <notifications group="form" :max="1" role="alert" aria-live="assertive" />
        <notifications group="profile-update-success" position="top right" :max="1">
            <template #body="props">
                <div class="notification profile-update-notification">
                    <a class="close" @click="props.close">
                        <i class="fa fa-fw fa-close" aria-hidden="true" />
                    </a>
                    <div class="row">
                        <div class="col-xs-12" style="padding-left: 0px">
                            <div v-if="auth.isLoggedIn()" class="notification-img col-xs-2 col-sm-1">
                                <template v-if="auth.user().avatar">
                                    <img :src="auth.user().avatar" style="width: 24px; height: 24px" class="img-circle profile_img" alt="profile image" />
                                </template>
                                <template v-else>
                                    <span class="badge_profile"
                                        ><span> {{ initials }} </span></span
                                    >
                                </template>
                            </div>
                            <div class="col-xs-10 col-sm-10">
                                <div class="notification-title">
                                    {{ props.item.title }}
                                </div>
                                <div class="notification-content" v-html="props.item.text" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </notifications>
        <notifications group="download-report" position="top right">
            <template #body="props">
                <div class="vue-notification-template vue-notification success">
                    <div class="notification-title">
                        {{ props.item.title }}
                        <div class="close" @click="props.close">
                            <i class="fa fa-fw fa-close" aria-hidden="true" />
                        </div>
                    </div>

                    <div class="notification-content">
                        <span v-html="props.item.text" />&nbsp;
                        <template v-if="props.item.title == 'Success'">
                            Click
                            <a @click.prevent="downloadReport(props.item.data)">
                                <b>here</b>
                            </a>
                            to download.
                        </template>
                    </div>
                </div>
            </template>
        </notifications>

        <supportWidget v-if="auth.user().role != 'Student'" />

        <div id="statusUpdateModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="statusUpdateModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="statusUpdateModal-title" class="modal-title">Status Updates</h2>
                    </div>
                    <div class="modal-body">
                        <b>Status Update:</b>
                        <p class="marginBottom8">Related to answer selection and activity submission on IRAT & TRAT's</p>

                        <b>Issue/s</b>
                        <p class="marginBottom8">
                            Recently there were some incidents related to learners experiencing errors during the IRAT and TRAT activity process. These errors
                            caused abnormal usage behaviour, intermittent time outs, and login issues.
                        </p>

                        <b>Action/s Taken</b>
                        <p class="marginBottom8">
                            We have disabled the function/s that kicks learners out of activities. This means that when the IRAT/TRAT timer reaches zero or
                            timeout, learners will NOT be kicked out or log out; instead, they will still be allowed to continue with the activities. This will
                            allow the teacher to manually manage the time taken provided to the learners to complete. This action will minimise the
                            inconvenience of the “Issue/s” stated.
                        </p>

                        <b>What’s Next</b>
                        <p class="marginBottom8">We have identified and rectifying the issue/s with high priority assigned to it.</p>

                        <b>Timeline Recovery</b>
                        <p>
                            We are expecting that the issue/s to be solved within the next 72 hours; or a little more time is anticipated depending on the
                            server/coding restriction. Meanwhile, please
                            <a class="link" href="mailto:support@intedashboard.com">email</a>
                            us if you need assistance and we will get back to you as soon as we possibly can!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="paymentAlertModal"
            class="modal danger-modal paymentAlertModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="paymentAlertModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closePaymentModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="paymentAlertModal-title" class="modal-title">
                            <template v-if="notPaid && !isExpired">
                                <template v-if="paidOffset > 0"> Payment Overdue </template>
                                <template v-else> Free Trial Expiring Soon </template>
                            </template>
                            <template v-else-if="isExpired"> Account Expired </template>
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <template v-if="notPaid && !isExpired">
                            <template v-if="paidOffset > 0">
                                <h2 class="marginTop40">
                                    <b>
                                        Payment Overdue for
                                        <br />
                                        <span class="dangerColor"> {{ abs(paidOffset) }} day(s) </span>
                                    </b>
                                </h2>

                                <p>
                                    You have an overdue payment.
                                    <br />
                                    Please make the payment as soon as possible
                                    <br />
                                    to avoid any disruptions to your service.
                                </p>
                            </template>
                            <template v-else>
                                <h2 class="marginTop40">
                                    <b>
                                        Your free trial will expire in
                                        <br />
                                        <span class="dangerColor"> {{ abs(paidOffset) }} day(s) </span>
                                    </b>
                                </h2>

                                <p>
                                    Please take the necessary actions
                                    <br />
                                    to renew your account.
                                </p>
                            </template>
                        </template>

                        <template v-else-if="isExpired">
                            <h2 class="marginTop40">
                                <b>Your account has expired already.</b>
                            </h2>
                            <p>
                                Your account has expired.
                                <br />
                                Please take the necessary actions
                                <br />
                                to renew your account.
                            </p>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" @click="closePaymentModal()">Cancel</button>
                        <router-link v-slot="{ navigate }" :to="{ name: 'payments' }" custom>
                            <button class="btn btn-primary" data-dismiss="modal" @click="navigate">Make Payment</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from './../../../components/auth/auth';
export default {
    components: {
        topbar: require(`./partials/topbar.vue`).default,
        sidebar: require(`./partials/sidebar.vue`).default,
        foot: require(`./partials/footer.vue`).default,
        supportWidget: require(`./partials/supportWidget.vue`).default,
    },
    data() {
        let auth = new KrAuth();
        return {
            auth: auth,
            initials: (auth.user().firstname ? auth.user().firstname.charAt(0) : '') + (auth.user().lastname ? auth.user().lastname.charAt(0) : ''),
            paidOffset: 0,
        };
    },
    computed: {
        notPaid() {
            //return true;
            if (this.auth.user().role == 'Student') {
                if (!['Active (Paid)'].includes(this.auth.user().status) && !['Expired'].includes(this.auth.user().status)) {
                    if (this.auth.user().account.paymentMethod == 'Student-Paid' && !this.auth.user().hasTeacherAccount) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
        isExpired() {
            if (this.auth.user().role == 'Student') {
                if (this.auth.user().status == 'Expired') {
                    if (this.auth.user().account.paymentMethod == 'Student-Paid' && !this.auth.user().hasTeacherAccount) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
        isLapsed() {
            if (this.auth.user().role == 'Student') {
                if (this.auth.user().status == 'Lapsed') {
                    if (this.auth.user().account.paymentMethod == 'Student-Paid' && !this.auth.user().hasTeacherAccount) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
        showCommunityBanner() {
            if (['Super Admin', 'Teacher'].includes(this.auth.user().role) && !this.auth.isImpersonation()) {
                if (!['activities.tests.questions', 'activities.tests.dashboard', 'tests.proper', 'tests.preview.rat'].includes(this.$route.name)) {
                    return true;
                }
            }
            return false;
        },
    },
    watch: {
        auth: {
            handler(newValue) {
                if (newValue) {
                    if (this.auth.isImpersonation()) {
                        this.$nextTick(() => {
                            this.updateTopNavHeightImpersonate();
                        });
                    }
                }
            },
            deep: true,
        },
        '$route.name': {
            handler() {
                this.$nextTick(() => {
                    if (this.showCommunityBanner) {
                        this.updateTopNavHeightCommunity();
                    } else if (!this.showCommunityBanner && !this.auth.isImpersonation()) {
                        $('.top_nav.desktop').css('top', '0px');
                    }

                    if (this.$route.name == 'tests.evaluation-v2') {
                        $('body').addClass('test-evaluationV2');
                    } else {
                        $('body').removeClass('test-evaluationV2');
                    }
                });
            },
        },
    },
    created() {
        $('body').removeClass('login');
        $('body').attr('class', 'nav-sm chatbar-hidden');
        //$("body").attr("class", "nav-none chatbar-hidden");
        $('#app').attr('class', 'wrapper');
        this.updateUI();
        if (this.notPaid) {
            let a = moment();
            let b = moment.utc(this.auth.user().dueDate, 'YYYY-MM-DDThh:mm:ssZ').local();
            this.paidOffset = a.diff(b, 'days');
        }

        // window.addEventListener('load', () => {
        //     $('.top_banner_message_placeholder').height(
        //         this.$refs.topNavHeight.offsetHeight + 'px'
        //     );

        //     $('.col-md-3.left_col').css(
        //         'height',
        //         'calc(100% - ' + this.$refs.topNavHeight.offsetHeight + 'px)'
        //     );
        // });
        if (this.auth.isImpersonation()) {
            window.addEventListener('load', () => {
                this.updateTopNavHeightImpersonate();
            });
        }

        if (this.showCommunityBanner) {
            window.addEventListener('load', () => {
                this.updateTopNavHeightCommunity();
            });
        }
    },
    mounted() {
        this.updateUI();
        if (this.notPaid || this.isExpired) {
            $('.paymentAlertModal').modal('show');
        }

        this.$nextTick(() => {
            // this.updateTopNavHeight();

            if (this.auth.isImpersonation()) {
                this.updateTopNavHeightImpersonate();
            }

            if (this.showCommunityBanner) {
                this.updateTopNavHeightCommunity();
            }
        });

        // window.addEventListener('resize', this.updateTopNavHeight);
        if (this.auth.isImpersonation()) {
            window.addEventListener('resize', this.updateTopNavHeightImpersonate);
        }

        if (this.showCommunityBanner) {
            window.addEventListener('resize', this.updateTopNavHeightCommunity);
        }
    },
    afterUpdate() {
        this.updateUI();
        if (this.notPaid || this.isExpired) {
            $('#paymentAlertModal').modal('show');
        }
    },
    beforeUnmount() {
        // window.removeEventListener('resize', this.updateTopNavHeight);
        if (this.auth.isImpersonation()) {
            window.removeEventListener('resize', this.updateTopNavHeightImpersonate);
        }

        if (this.showCommunityBanner) {
            window.removeEventListener('resize', this.updateTopNavHeightCommunity);
        }
    },
    methods: {
        abs(num) {
            return Math.abs(num);
        },
        downloadReport(report) {
            axios({
                url: report.link,
                method: 'POST',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', report.filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        logout() {
            Events.fire('logout', { redirect: { name: 'auth.login' } });

            $('.top_nav.desktop').css('top', '0');
        },
        updateUI() {
            var that = this;
            var CURRENT_URL = window.location.href.split('?')[0],
                $BODY = $('body'),
                $MENU_TOGGLE = $('#menu_toggle'),
                $SIDEBAR_MENU = $('#sidebar-menu'),
                $SIDEBAR_FOOTER = $('.sidebar-footer'),
                $LEFT_COL = $('.left_col'),
                // $RIGHT_COL = $('.right_col'),
                $NAV_MENU = $('.nav_menu'),
                $FOOTER = $('footer');
            // var setContentHeight =
            //     // reset height
            //     _.debounce(function()
            //         {
            //             $RIGHT_COL.css('min-height', 'calc(100vh - 47px)');
            //             var bodyHeight = $BODY.outerHeight(),
            //                 footerHeight = $BODY.hasClass('footer_fixed') ? 0 : $FOOTER.height(),
            //                 leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
            //                 contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;
            //             // normalize content
            //             contentHeight -= $NAV_MENU.height() + footerHeight;
            //             $RIGHT_COL.css('min-height', contentHeight);
            //         },1000, {
            //             'leading': true,
            //             'trailing': false
            //         });
            var menu_toggle = function (bit = 0) {
                if (bit == 0 && $BODY.hasClass('nav-none') && $BODY.hasClass('chatbar-visible')) {
                    $BODY.toggleClass('chatbar-visible chatbar-hidden');
                }
                if ($BODY.hasClass('nav-sm')) {
                    $SIDEBAR_MENU.find('li.active ul').hide();
                    $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
                } else {
                    $SIDEBAR_MENU.find('li.active-sm ul').show();
                    $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
                }
                $BODY.toggleClass('nav-sm nav-none');
                //setContentHeight();
            };
            // toggle small or large menu
            $MENU_TOGGLE.on('click', function () {
                menu_toggle(0);
            });
            $(document).ready(function () {
                window.addEventListener('resize', function (event) {
                    // do stuff here
                    if ($(window).width() > 991) {
                        if (!$BODY.hasClass('test')) {
                            $BODY.addClass('nav-sm').removeClass('nav-none');
                        }
                    } else {
                        $BODY.addClass('nav-none').removeClass('nav-sm');
                    }
                });
            });

            if (this.$route.name == 'tests.evaluation-v2') {
                $('body').addClass('test-evaluationV2');
            } else {
                $('body').removeClass('test-evaluationV2');
            }
        },
        openStatusUpdateModal() {
            $('#statusUpdateModal').modal('show');
        },
        // updateTopNavHeight() {
        //     $('.top_banner_message_placeholder').height(
        //         this.$refs.topNavHeight.offsetHeight + 'px'
        //     );
        // },
        updateTopNavHeightImpersonate() {
            if (!this.$refs.topNavHeightImpersonate) {
                return;
            }
            $('.top_banner_message_placeholder_impersonate').height(this.$refs.topNavHeightImpersonate.offsetHeight + 'px');

            $('.top_nav.desktop').css('top', this.$refs.topNavHeightImpersonate.offsetHeight + 'px');
        },
        updateTopNavHeightCommunity() {
            if (!this.$refs.topNavHeightCommunity) {
                return;
            }
            $('.top_banner_message_placeholder_community').height(this.$refs.topNavHeightCommunity.offsetHeight + 'px');

            $('.top_nav.desktop').css('top', this.$refs.topNavHeightCommunity.offsetHeight + 'px');
        },
        closePaymentModal() {
            $('.paymentAlertModal').modal('hide');
        },
        closeCommunityBanner() {
            $('.top_banner_message_placeholder_community').height('0px');
            $('.top_nav.desktop').css('top', '0px');
            $('.top_banner_message_community').addClass('d-none');
        },
    },
};
</script>

<style>
.badge_profile {
    background: #2a3f54;
    color: white;
    width: 24px;
    height: 24px;
    line-height: 1;
    font-size: 24px;
}
</style>
<style scoped lang="scss">
.payment-alert {
    cursor: pointer;
}
.notification-content a {
    color: #0071be;
}
.sticky {
    position: sticky;
    top: 60px;
    z-index: 100;
}

.top_banner_message,
.top_banner_message_impersonate,
.top_banner_message_community {
    position: fixed;
    top: 0;
    z-index: 1050;
    width: 100%;
    padding: 10px;
    border-radius: 0;
    text-align: center;
}

.top_banner_message_community {
    a {
        button {
            &:hover,
            &:focus {
                background: #23356d;
            }
        }
    }
}

.impersonate-banner-container,
.community-banner-container {
    height: auto;
}
</style>
