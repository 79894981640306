<template>
    <div
        ref="modal"
        class="modal default-modal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="'teammatesInfo-title'"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 :id="'teammatesInfo-title'" class="modal-title">
                        <template v-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        <template v-else> View {{ previewModel.team.name }} </template>
                    </h2>
                </div>
                <div class="modal-body">
                    <div class="marginBottom20 collapsingQuestion">
                        <button
                            class="btn"
                            :data-target="'#viewQuestion-' + getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1)"
                            data-toggle="collapse"
                            aria-expanded="false"
                            :aria-controls="'viewQuestion-' + getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1)"
                        >
                            Question
                            {{ getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1) }}
                        </button>
                        <div :id="'viewQuestion-' + getQuestionNo(previewModel.question.group - 1, previewModel.question.order - 1)" class="collapse padding10">
                            <template v-if="previewModel.question.attachments && previewModel.question.attachments.length != 0">
                                <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
                                <div class="textLink">
                                    <div v-for="(file, idx) in previewModel.question.attachments" class="flex">
                                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                                        <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                    </div>
                                </div>
                            </template>

                            <div class="question">
                                <question-display :test-obj="testObj" :question="previewModel.question" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flexOnly marginBottom5 align-items">
                            <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                            <span class="lineHeight08 fontBold">Elaboration</span>
                        </div>
                        <div v-if="previewModel.comment">
                            <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                        </div>
                        <div v-else>-</div>
                    </div>
                    <div class="marginTop20">
                        <div class="flexOnly marginBottom5 align-items">
                            <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true" />
                            <span class="lineHeight08 fontBold">Attachment(s)</span>
                        </div>
                        <div>
                            <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                <div v-for="(file, idx) in previewModel.attachments" class="flex">
                                    <span class="marginRight5">{{ idx + 1 }}.</span>
                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                </div>
                            </template>
                            <template v-else> - </template>
                        </div>
                    </div>
                    <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                        <div class="flexOnly marginBottom5 align-items">
                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                            <span class="lineHeight08 fontBold">Team Members</span>
                        </div>
                        <div>
                            <ul v-for="(student, idx) in previewModel.team.members">
                                <li>{{ student.displayName }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click="closeModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['read']);
import questionDisplay from '../../../../../../../questions/partials/question-display.vue';
import { ref, nextTick, watch } from 'vue';

const modal = ref(null);
const testObj = ref({});
const previewModel = ref({
    type: 'team',
    student: {},
    team: {},
    comment: {},
    attachments: [],
    question: {},
});

const openModal = () => {
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const read = (q = {}, m = {}, t = '', test = {}) => {
    if (t == 'student') {
        previewModel.value.type = 'student';
        previewModel.value.student = m.student;
    } else {
        previewModel.value.type = 'team';
        previewModel.value.team = m.team;
    }

    previewModel.value.question = q;
    previewModel.value.comment = m.comment;
    previewModel.value.attachments = m.attachments ? m.attachments : [];

    testObj.value = test;

    openModal();
};

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += testObj.value.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};

defineExpose({ read });
</script>
