<template>
    <div class="padding20">
        <div class="question presentationMode">
            <kr-math :input="question.question" :safe="!question.questionIsHTML" />
            <template v-if="question.attachments && question.attachments.length != 0">
                <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
                <div class="textLink">
                    <div v-for="(file, idx) in question.attachments" class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                        <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                    </div>
                </div>
            </template>
        </div>

        <fieldset>
            <legend class="margin0">Sequence Order</legend>
            <template v-for="(option, option_idx) in question.optionKeys">
                <div class="discussionOption">
                    <div class="sequencing-div-instructor" :class="{ displayGrid: !isMobileView, noLabel: !question.displayAnswer }">
                        <div :class="{ 'grid-order-2': !isMobileView }">
                            <p :id="'sequence-instructor-' + (option_idx + 1)">
                                {{ question.options[option_idx].content }}
                            </p>
                        </div>

                        <div v-if="question.displayAnswer" :class="{ 'grid-order-1': !isMobileView, marginTop20: isMobileView }">
                            <label :for="'sequence-instructor-' + (option_idx + 1)" class="correct">
                                <span> Correct Order </span>
                                <br v-if="!isMobileView" />
                                {{ question.options[option_idx].order }}
                            </label>
                        </div>
                    </div>

                    <div v-if="question.displayAnswerStatistics" class="marginTop10">
                        <label
                            :id="'percentage-qns-' + getQuestionNo(question.group - 1, question.order - 1) + '-order-' + question.options[option_idx].order"
                            class="control-label"
                        >
                            Percentage of team/s that got it correctly for Order {{ question.options[option_idx].order }}
                        </label>
                        <div
                            class="discussion-bar"
                            role="progressbar"
                            :aria-labelledby="
                                'percentage-qns-' + getQuestionNo(question.group - 1, question.order - 1) + '-order-' + question.options[option_idx].order
                            "
                            :aria-valuenow="option.percent.toFixed(0)"
                        >
                            <template v-if="option.percent.toFixed(0) >= 10">
                                <div class="barProgress" :style="'width:' + option.percent.toFixed(0) + '%'">{{ option.percent.toFixed(0) }}%</div>
                            </template>
                            <template v-else>
                                <div class="col-xs-12">{{ option.percent.toFixed(0) }}%</div>
                            </template>
                        </div>

                        <div class="marginTop10">
                            <label class="control-label">
                                {{ option.answersPerOption.length }}
                                <template v-if="option.answersPerOption.length <= 1"> Response </template>
                                <template v-else> Responses </template> got it correct
                            </label>
                            <div class="discussion-buttons">
                                <template v-for="(model, model_idx) in option.answersPerOption">
                                    <template v-if="model.student">
                                        <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown marginRight20">
                                            <button
                                                id="popOver"
                                                class="btn subBtn marginBottom10"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                :aria-label="model.student.fullname"
                                            >
                                                {{ truncate(model.student.fullname) }}&nbsp;<i class="far fa-comment-dots" />
                                            </button>
                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                <div>
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-comments marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold">Elaboration</span>
                                                    </div>
                                                    <div v-if="model.comment">
                                                        <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                        <template v-if="model.comment.length > 400">
                                                            <button
                                                                class="btn btn-link marginTop10 fw-normal"
                                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                    </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <div class="marginTop20">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-paperclip marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                    </div>
                                                    <div>
                                                        <template v-if="model.attachments && model.attachments.length != 0">
                                                            <div v-for="(file, idx) in model.attachments" class="flex maxHeight100px">
                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                    file.filename
                                                                }}</a>
                                                            </div>
                                                            <template v-if="model.attachments.length > 4">
                                                                <button
                                                                    class="btn btn-link marginTop10 fw-normal"
                                                                    @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                                                >
                                                                    View More
                                                                </button>
                                                            </template>
                                                        </template>
                                                        <template v-else> - </template>
                                                    </div>
                                                </div>
                                                <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ul v-for="(student, idx) in model.team.members">
                                                            <li>
                                                                {{ student.displayName }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <template v-if="model.team.members.length > 4">
                                                        <button
                                                            class="btn btn-link marginTop10 fw-normal"
                                                            @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                                        >
                                                            View More
                                                        </button>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-else>
                                            <button
                                                class="btn subBtn marginBottom10 cursor-norm"
                                                :aria-label="model.student.fullname"
                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                            >
                                                {{ truncate(model.student.fullname) }}
                                            </button>
                                        </template>
                                    </template>

                                    <template v-else-if="model.team">
                                        <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown marginRight20">
                                            <button
                                                id="popOver"
                                                class="btn subBtn marginBottom10"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                :aria-label="model.team.name"
                                            >
                                                {{ truncate(model.team.name) }}&nbsp;<i class="far fa-comment-dots" />
                                            </button>
                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                <div>
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-comments marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold"> Elaboration </span>
                                                    </div>
                                                    <div v-if="model.comment">
                                                        <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                        <template v-if="model.comment.length > 400">
                                                            <button
                                                                class="btn btn-link marginTop10 fw-normal"
                                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                    </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <div class="marginTop20">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-paperclip marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold"> Attachment(s) </span>
                                                    </div>
                                                    <div>
                                                        <template v-if="model.attachments && model.attachments.length != 0">
                                                            <div
                                                                v-for="(file, idx) in model.attachments"
                                                                :key="'attachment_file_' + idx"
                                                                class="flex maxHeight100px"
                                                            >
                                                                <span class="marginRight5"> {{ idx + 1 }}. </span>
                                                                <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                    file.filename
                                                                }}</a>
                                                            </div>
                                                            <template v-if="model.attachments.length > 4">
                                                                <button
                                                                    class="btn btn-link marginTop10 fw-normal"
                                                                    @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                                >
                                                                    View More
                                                                </button>
                                                            </template>
                                                        </template>
                                                        <template v-else> - </template>
                                                    </div>
                                                </div>
                                                <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ul v-for="(student, idx) in model.team.members" :key="'team_members_' + idx">
                                                            <li>
                                                                {{ student.displayName }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <template v-if="model.team.members.length > 4">
                                                        <button
                                                            class="btn btn-link marginTop10 fw-normal"
                                                            @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                        >
                                                            View More
                                                        </button>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-else>
                                            <div class="dropdown marginRight20">
                                                <button
                                                    id="popOver"
                                                    class="btn subBtn marginBottom10"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="model.team.name"
                                                >
                                                    {{ truncate(model.team.name) }}
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                    <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-users marginRight8 greyText" />
                                                            <span class="lineHeight08 fontBold"> Team Members </span>
                                                        </div>
                                                        <div class="maxHeight100px">
                                                            <ul v-for="(student, idx) in model.team.members">
                                                                <li>
                                                                    {{ student.displayName }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <template v-if="model.team.members.length > 4">
                                                            <button
                                                                class="btn btn-link marginTop10 fw-normal"
                                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </fieldset>

        <TeammatesInfoComponent ref="teammatesInfoComponent" />
    </div>
</template>

<script setup>
import TeammatesInfoComponent from '../actions/teammatesInfo.vue';

const props = defineProps({
    testObj: {
        type: Object,
        default: null,
    },
    question: {
        type: Object,
        default: null,
    },
});

const truncate = (str, characters = 10) => {
    return _.truncate(str, {
        length: characters,
        omission: '...',
    });
};

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += props.testObj.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};
</script>
