<template>
    <div>
        <div>
            <caption class="visible-hidden">
                Question Stem
            </caption>
            <kr-math
                :input="question.question"
                :safe="!question.question.questionIsHTML"
                class="question"
                :can-highlight="true"
                :question-uuid="question.uuid"
            />
        </div>

        <template v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
            <div class="textLink">
                <template v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename">
                    <div class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                        <template
                            v-if="
                                (testObj.type == 'application' && (!isTestCompleted || (isTestCompleted && testObj.allowStudentsToViewAttachments))) ||
                                testObj.type != 'application'
                            "
                        >
                            <template v-if="isPdf(file)">
                                <template v-if="testObj.settings.allowPdfDownload">
                                    <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                        {{ file.filename }}
                                    </a>
                                </template>
                                <template v-else>
                                    <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                                </template>
                            </template>
                            <template v-else>
                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                            </template>
                        </template>
                        <template v-else>
                            <a v-tooltip="{ content: 'Viewing disabled' }" class="textLink disabled" :aria-label="file.filename">{{ file.filename }} </a>
                        </template>
                    </div>
                </template>
            </div>
        </template>

        <fieldset class="marginTop20">
            <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} sequencing</legend>
            <template v-for="(option, idx) of question.options">
                <div class="sequencing-div-student" :class="{ filled: answer.includes(option.key), displayGrid: !isMobileView, disabled: disabled }">
                    <div :class="{ 'grid-order-2': !isMobileView }">
                        <p :id="'sequence-student-' + (idx + 1)">{{ option.content }}</p>
                    </div>

                    <div :class="{ 'grid-order-1': !isMobileView, marginTop20: isMobileView }">
                        <label :for="'sequence-student-select-' + (idx + 1)" class="control-label">
                            <template v-if="disabled"> Selected order </template>
                            <template v-else> Select the correct order </template>
                        </label>
                        <div class="form-dropdown" :class="{ 'disabled ': disabled }">
                            <select
                                :id="'sequence-student-select-' + (idx + 1)"
                                v-tooltip="disabled ? 'Only Team Leader can change the answer' : ''"
                                class="form-control"
                                :class="{ 'disabled hasTooltip': disabled }"
                                :aria-disabled="disabled"
                                @change="handleChange($event, idx)"
                            >
                                <option value="" :disabled="disabled" :selected="answer[i - 1] == null">Select the order</option>
                                <template v-for="i in question.options.length">
                                    <option :value="i" :disabled="disabled" :selected="answer[i - 1] == option.key">{{ i }}</option>
                                </template>
                            </select>
                        </div>
                    </div>
                </div>
            </template>
        </fieldset>
    </div>
</template>

<script setup>
import { computed } from 'vue';
defineEmits(['update:answer']);
const props = defineProps({
    testObj: {
        type: Object,
        default: null,
    },
    question: {
        type: Object,
        default: null,
    },
    answer: {
        type: Array,
        default: function () {
            return [];
        },
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isTestCompleted: {
        type: Boolean,
        default: false,
    },
});

const answer = computed({
    // Use computed to wrap the object
    get: () => props.answer,
    set: (value) => emit('update:answer', value),
});

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += props.testObj.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};

const handleChange = (event, optionIdx) => {
    let key = props.question.options[optionIdx].key;

    for (var i = 0; i < answer.value.length; i++) {
        if (answer.value[i] == key) {
            answer.value[i] = null;
            break;
        }
    }
    answer.value[event.target.value - 1] = key;
};
</script>
