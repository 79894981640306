<template>
    <div v-if="component_done_loading">
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div>Evaluation Analysis</div>
            </template>
            <template #content>
                <div class="nav-tabsContainer">
                    <div class="links">
                        <ul>
                            <li
                                v-if="testObj.evaluationSettings.evaluateTeammates || testObj.evaluationSettings.evaluateThemselves"
                                :class="{ active: tab == 'evaIndividual' }"
                            >
                                <a class="nav-link" data-toggle="tab" href="#evaIndividual" aria-label="Individual Evaluation" @click="tab = 'evaIndividual'"
                                    >INDIVIDUAL EVALUATION</a
                                >
                            </li>
                            <li v-if="testObj.evaluationSettings.evaluateTeam" :class="{ active: tab == 'evaOnTeam' }">
                                <a class="nav-link" data-toggle="tab" href="#evaOnTeam" aria-label="Team Evaluation" @click="tab = 'evaOnTeam'"
                                    >TEAM EVALUATION</a
                                >
                            </li>
                            <li v-if="testObj.evaluationSettings.evaluateInstructor" :class="{ active: tab == 'evaOnInstructor' }">
                                <a
                                    class="nav-link"
                                    data-toggle="tab"
                                    href="#evaOnInstructor"
                                    aria-label="Instructor Evaluation"
                                    @click="tab = 'evaOnInstructor'"
                                    >INSTRUCTOR EVALUATION</a
                                >
                            </li>
                            <li v-if="testObj.settings.enableMichaelsenPointDistribution" :class="{ active: tab == 'michelsenMethod' }">
                                <a class="nav-link" data-toggle="tab" href="#michelsenMethod" aria-label="Michaelsen Method" @click="tab = 'michelsenMethod'"
                                    >MICHAELSEN METHOD</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="flexOny marginTop30">
                    <div class="inlineFlex">
                        <div v-if="tab != 'michelsenMethod'" class="flex align-items">
                            <div class="form-dropdown viewFilter marginLeft0 minWidth0">
                                <select v-model="questionSelection" class="form-control" :aria-label="questionSelection">
                                    <option value="" disabled selected>Choose a question</option>
                                    <template v-for="(question, key) in questions">
                                        <option :value="key">{{ question }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div v-if="tab != 'evaOnInstructor'" class="flex align-items" :class="{ marginLeft30: tab != 'michelsenMethod' }">
                            <div class="form-dropdown viewFilter marginLeft0 minWidth0">
                                <select v-model="teamSelection" class="form-control" :aria-label="teamSelection">
                                    <option value="" disabled selected>Choose a team</option>
                                    <template v-for="(team, key) in teams">
                                        <option :value="key">{{ team }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div v-else class="flex align-items marginLeft30">
                            <div class="form-dropdown viewFilter marginLeft0 minWidth0">
                                <select v-model="instructorSelection" class="form-control" :aria-label="instructorSelection">
                                    <option value="" disabled selected>Choose an instructor</option>
                                    <template v-for="(instructor, key) in instructors">
                                        <option :value="key">{{ instructor }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <button class="btn btn-primary mainBtn" :disabled="!canSearch ? true : undefined" aria-label="Search" @click="fetch()">
                            Search<i class="fas fa-search marginLeft8" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>

                <div class="tab-content marginTop20">
                    <div
                        v-if="testObj.evaluationSettings.evaluateTeammates || testObj.evaluationSettings.evaluateThemselves"
                        id="evaIndividual"
                        :class="{ active: tab == 'evaIndividual' }"
                        class="tab-pane fade in"
                    >
                        <div v-if="!(questionSelection && teamSelection)" class="fontItalic">Choose a question and team above and press "Search".</div>

                        <div v-if="questionSelection && teamSelection" class="flexSpaceBetween alignBaseline">
                            <div class="flexOnly alignFlexStart">
                                <button
                                    v-if="individualData.question.question"
                                    class="btn gradient btn-default marginRight10"
                                    :aria-label="individualData.question.questionNo"
                                    @click.prevent="openPreviewQuestionModal(individualData.question)"
                                >
                                    {{ individualData.question.questionNo }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true"></i>
                                </button>
                                <span class="question displayInlineBlock" v-html="individualData.question.question"></span>
                            </div>
                            <div v-if="individualData.question.isRequired" class="statusTag statusTagDanger fontSize12 padding5">Required</div>
                        </div>

                        <template v-if="individualData.question.type == 'rating' && questionSelection && teamSelection">
                            <div class="table-responsive newEvaluationTable border1pxGrey marginTop20">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th rowspan="3" class="width150px">Rating Received By</th>
                                            <th rowspan="3" class="width150px">Status</th>
                                            <th
                                                :colspan="
                                                    ratingHaveDescription
                                                        ? individualData.individualEvaluation.length * 3
                                                        : individualData.individualEvaluation.length * 2
                                                "
                                            >
                                                Rating Received From
                                            </th>
                                            <th rowspan="3">Total</th>
                                            <th rowspan="3">Avg</th>
                                            <th rowspan="3">Team Avg</th>
                                        </tr>
                                        <tr>
                                            <template v-for="(individual, idx) in individualData.individualEvaluation">
                                                <th :colspan="ratingHaveDescription ? 3 : 2" class="whiteSpaceNowrap">
                                                    {{ individual.receiver }}
                                                </th>
                                            </template>
                                        </tr>
                                        <tr>
                                            <template v-for="(individual, idx) in individualData.individualEvaluation">
                                                <th class="whiteSpaceNowrap">Scale</th>
                                                <th class="whiteSpaceNowrap">Label</th>
                                                <th v-if="ratingHaveDescription" class="whiteSpaceNowrap">Description</th>
                                            </template>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <template v-for="(individual, idx) in individualData.individualEvaluation.slice(0, 1)">
                                            <tr>
                                                <td>
                                                    {{ individual.receiver }}
                                                </td>

                                                <td>
                                                    <div v-if="individual.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">
                                                        Timed Out
                                                    </div>
                                                    <div v-if="individual.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                        Not Started
                                                    </div>
                                                    <div v-if="individual.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">
                                                        Ongoing
                                                    </div>
                                                    <div v-if="individual.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">
                                                        Submitted
                                                    </div>
                                                    <div v-if="individual.status.tally" class="marginTop10">{{ individual.status.tally }}</div>
                                                </td>

                                                <template v-for="(giver, giver_idx) in individual.givers">
                                                    <td
                                                        :class="{
                                                            disabled:
                                                                (individualData.individualEvaluation[giver_idx].receiver == individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (individualData.individualEvaluation[giver_idx].receiver != individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                    >
                                                        {{ lookForWeight(idx, individualData.individualEvaluation[giver_idx].receiver) }}
                                                    </td>

                                                    <td
                                                        :class="{
                                                            disabled:
                                                                (individualData.individualEvaluation[giver_idx].receiver == individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (individualData.individualEvaluation[giver_idx].receiver != individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                    >
                                                        {{ lookForLabel(idx, individualData.individualEvaluation[giver_idx].receiver) }}
                                                    </td>

                                                    <td
                                                        v-if="ratingHaveDescription"
                                                        :class="{
                                                            disabled:
                                                                (individualData.individualEvaluation[giver_idx].receiver == individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (individualData.individualEvaluation[giver_idx].receiver != individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                    >
                                                        {{ lookForRubric(idx, individualData.individualEvaluation[giver_idx].receiver) }}
                                                    </td>
                                                </template>

                                                <td>
                                                    {{ totalEvaluationScale(idx) }}
                                                </td>

                                                <td>
                                                    {{ individual.receiverAvg }}
                                                </td>

                                                <template v-if="individual.givers.length != 0">
                                                    <td :rowspan="individual.givers.length">
                                                        {{ individual.teamMean.toFixed(2) }}
                                                    </td>
                                                </template>
                                            </tr>
                                        </template>

                                        <template v-for="(individual, idx) in individualData.individualEvaluation.slice(1)">
                                            <tr class="notFirstRow">
                                                <td>
                                                    {{ individual.receiver }}
                                                </td>

                                                <td>
                                                    <div v-if="individual.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">
                                                        Timed Out
                                                    </div>
                                                    <div v-if="individual.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                        Not Started
                                                    </div>
                                                    <div v-if="individual.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">
                                                        Ongoing
                                                    </div>
                                                    <div v-if="individual.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">
                                                        Submitted
                                                    </div>
                                                    <div v-if="individual.status.tally" class="marginTop10">{{ individual.status.tally }}</div>
                                                </td>

                                                <template v-for="(giver, giver_idx) in individual.givers">
                                                    <td
                                                        :class="{
                                                            disabled:
                                                                (individualData.individualEvaluation[giver_idx].receiver == individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (individualData.individualEvaluation[giver_idx].receiver != individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                    >
                                                        {{ lookForWeight(idx + 1, individualData.individualEvaluation[giver_idx].receiver) }}
                                                    </td>

                                                    <td
                                                        :class="{
                                                            disabled:
                                                                (individualData.individualEvaluation[giver_idx].receiver == individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (individualData.individualEvaluation[giver_idx].receiver != individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                    >
                                                        {{ lookForLabel(idx + 1, individualData.individualEvaluation[giver_idx].receiver) }}
                                                    </td>

                                                    <td
                                                        v-if="ratingHaveDescription"
                                                        :class="{
                                                            disabled:
                                                                (individualData.individualEvaluation[giver_idx].receiver == individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (individualData.individualEvaluation[giver_idx].receiver != individual.receiver &&
                                                                    !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                    >
                                                        {{ lookForRubric(idx + 1, individualData.individualEvaluation[giver_idx].receiver) }}
                                                    </td>
                                                </template>

                                                <td>
                                                    {{ totalEvaluationScale(idx + 1) }}
                                                </td>

                                                <td>
                                                    {{ individual.receiverAvg }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>

                        <template v-if="individualData.question.type == 'openended' && questionSelection && teamSelection">
                            <table class="table marginTop20">
                                <thead>
                                    <tr>
                                        <th class="width150px">Receiver</th>
                                        <th class="width150px">Status</th>
                                        <th class="width150px">Giver</th>
                                        <th>Evaluation Given</th>
                                        <th class="width266px minWidth266px maxWidth266px">Sentiment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(individual, idx) in individualData.individualEvaluation">
                                        <tr></tr>
                                        <tr class="thisRow">
                                            <td :rowspan="individual.givers.length">
                                                {{ individual.receiver }}
                                            </td>
                                            <td :rowspan="individual.givers.length">
                                                <div v-if="individual.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">Timed Out</div>
                                                <div v-if="individual.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                    Not Started
                                                </div>
                                                <div v-if="individual.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">Ongoing</div>
                                                <div v-if="individual.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">
                                                    Submitted
                                                </div>
                                                <div v-if="individual.status.tally" class="marginTop10">{{ individual.status.tally }}</div>
                                            </td>
                                            <template v-if="individual.givers.length != 0">
                                                <td
                                                    :class="{
                                                        disabled:
                                                            (individual.givers[0].giver == individual.receiver &&
                                                                !testObj.evaluationSettings.evaluateThemselves) ||
                                                            (individual.givers[0].giver != individual.receiver &&
                                                                !testObj.evaluationSettings.evaluateTeammates),
                                                    }"
                                                >
                                                    {{ individual.givers[0].giver }}
                                                </td>
                                                <td
                                                    class="wordBreakWord whiteSpacePreWrap"
                                                    :class="{
                                                        disabled:
                                                            (individual.givers[0].giver == individual.receiver &&
                                                                !testObj.evaluationSettings.evaluateThemselves) ||
                                                            (individual.givers[0].giver != individual.receiver &&
                                                                !testObj.evaluationSettings.evaluateTeammates),
                                                    }"
                                                    v-html="individual.givers[0].response"
                                                ></td>
                                                <td
                                                    class="textAlignCenter"
                                                    :class="{
                                                        disabled:
                                                            (individual.givers[0].giver == individual.receiver &&
                                                                !testObj.evaluationSettings.evaluateThemselves) ||
                                                            (individual.givers[0].giver != individual.receiver &&
                                                                !testObj.evaluationSettings.evaluateTeammates),
                                                    }"
                                                    v-html="getSentiment(individual.givers[0].response)"
                                                ></td>
                                            </template>
                                        </tr>
                                        <template v-if="individual.givers.length > 1">
                                            <template v-for="(giver, giver_idx) in individual.givers.slice(1)">
                                                <tr class="thisRow">
                                                    <td
                                                        :class="{
                                                            disabled:
                                                                (giver.giver == individual.receiver && !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (giver.giver != individual.receiver && !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                        v-html="giver.giver"
                                                    ></td>
                                                    <td
                                                        class="wordBreakWord whiteSpacePreWrap"
                                                        :class="{
                                                            disabled:
                                                                (giver.giver == individual.receiver && !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (giver.giver != individual.receiver && !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                        v-html="giver.response"
                                                    ></td>
                                                    <td
                                                        class="textAlignCenter"
                                                        :class="{
                                                            disabled:
                                                                (giver.giver == individual.receiver && !testObj.evaluationSettings.evaluateThemselves) ||
                                                                (giver.giver != individual.receiver && !testObj.evaluationSettings.evaluateTeammates),
                                                        }"
                                                        v-html="getSentiment(giver.response)"
                                                    ></td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </template>
                    </div>

                    <div id="evaOnTeam" :class="{ active: tab == 'evaOnTeam' }" class="tab-pane fade in">
                        <div v-if="!(questionSelection && teamSelection)" class="fontItalic">Choose a question and team above and press "Search".</div>

                        <div v-if="questionSelection && teamSelection" class="flexSpaceBetween alignBaseline">
                            <div class="flexOnly alignFlexStart">
                                <button
                                    v-if="teamData.question.question"
                                    class="btn gradient btn-default marginRight10"
                                    :aria-label="teamData.question.questionNo"
                                    @click.prevent="openPreviewQuestionModal(teamData.question)"
                                >
                                    {{ teamData.question.questionNo }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true"></i>
                                </button>
                                <span class="question displayInlineBlock" v-html="teamData.question.question"></span>
                            </div>
                            <div v-if="teamData.question.isRequired" class="statusTag statusTagDanger fontSize12 padding5">Required</div>
                        </div>

                        <template v-if="teamData.question.type == 'rating' && questionSelection && teamSelection">
                            <div class="table-responsive newEvaluationTable border1pxGrey marginTop20">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="width150px">RECEIVER</th>
                                            <th class="width150px">GIVER</th>
                                            <th class="width150px">GIVER STATUS</th>
                                            <th>Scale</th>
                                            <th>Label</th>
                                            <th v-if="ratingHaveDescription">Description</th>
                                            <th>Avg</th>
                                            <th>Team Avg</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="thisRow">
                                            <td :rowspan="teamData.teamEvaluation.givers.length">
                                                {{ teamData.teamEvaluation.receiver }}
                                            </td>
                                            <template v-if="teamData.teamEvaluation.givers.length != 0">
                                                <td>
                                                    {{ teamData.teamEvaluation.givers[0].giver }}
                                                </td>
                                                <td>
                                                    <div
                                                        v-if="teamData.teamEvaluation.givers[0].status.status == 'Timed Out'"
                                                        class="tableStatusTag tableStatusTag-danger"
                                                    >
                                                        Timed Out
                                                    </div>
                                                    <div
                                                        v-if="teamData.teamEvaluation.givers[0].status.status == 'Not Started'"
                                                        class="tableStatusTag tableStatusTag-danger"
                                                    >
                                                        Not Started
                                                    </div>
                                                    <div
                                                        v-if="teamData.teamEvaluation.givers[0].status.status == 'Ongoing'"
                                                        class="tableStatusTag tableStatusTag-warning"
                                                    >
                                                        Ongoing
                                                    </div>
                                                    <div
                                                        v-if="teamData.teamEvaluation.givers[0].status.status == 'Submitted'"
                                                        class="tableStatusTag tableStatusTag-success"
                                                    >
                                                        Submitted
                                                    </div>
                                                    <div class="marginTop10">{{ teamData.teamEvaluation.givers[0].status.tally }}</div>
                                                </td>
                                                <td>
                                                    {{ lookForTeamWeights(0) }}
                                                </td>
                                                <td>
                                                    {{ lookForTeamLabels(0) }}
                                                </td>
                                                <td v-if="ratingHaveDescription">
                                                    {{ lookForTeamRubrics(0) }}
                                                </td>
                                                <td :rowspan="teamData.teamEvaluation.givers.length">{{ teamData.teamEvaluation.teamAvg }}</td>
                                                <td :rowspan="teamData.teamEvaluation.givers.length">{{ teamData.teamEvaluation.sectionMean }}</td>
                                            </template>
                                        </tr>
                                        <template v-if="teamData.teamEvaluation.givers.length > 1">
                                            <template v-for="(giver, giver_idx) in teamData.teamEvaluation.givers.slice(1)">
                                                <tr>
                                                    <td>
                                                        {{ giver.giver }}
                                                    </td>
                                                    <td>
                                                        <div v-if="giver.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">
                                                            Timed Out
                                                        </div>
                                                        <div v-if="giver.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                            Not Started
                                                        </div>
                                                        <div v-if="giver.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">Ongoing</div>
                                                        <div v-if="giver.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">
                                                            Submitted
                                                        </div>
                                                        <div class="marginTop10">{{ giver.status.tally }}</div>
                                                    </td>
                                                    <td>
                                                        {{ lookForTeamWeights(giver_idx + 1) }}
                                                    </td>
                                                    <td>
                                                        {{ lookForTeamLabels(giver_idx + 1) }}
                                                    </td>
                                                    <td v-if="ratingHaveDescription">
                                                        {{ lookForTeamRubrics(giver_idx + 1) }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>

                        <template v-if="teamData.question.type == 'openended' && questionSelection && teamSelection">
                            <table class="table marginTop20">
                                <thead>
                                    <tr>
                                        <th class="width150px">Receiver</th>
                                        <th class="width150px">Giver</th>
                                        <th class="width150px">Giver Status</th>
                                        <th>Evaluation Given</th>
                                        <th class="width266px minWidth266px maxWidth266px">Sentiment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="thisRow">
                                        <td :rowspan="teamData.teamEvaluation.givers.length">
                                            {{ teamData.teamEvaluation.receiver }}
                                        </td>
                                        <template v-if="teamData.teamEvaluation.givers.length != 0">
                                            <td>
                                                {{ teamData.teamEvaluation.givers[0].giver }}
                                            </td>
                                            <td>
                                                <div
                                                    v-if="teamData.teamEvaluation.givers[0].status.status == 'Timed Out'"
                                                    class="tableStatusTag tableStatusTag-danger"
                                                >
                                                    Timed Out
                                                </div>
                                                <div
                                                    v-if="teamData.teamEvaluation.givers[0].status.status == 'Not Started'"
                                                    class="tableStatusTag tableStatusTag-danger"
                                                >
                                                    Not Started
                                                </div>
                                                <div
                                                    v-if="teamData.teamEvaluation.givers[0].status.status == 'Ongoing'"
                                                    class="tableStatusTag tableStatusTag-warning"
                                                >
                                                    Ongoing
                                                </div>
                                                <div
                                                    v-if="teamData.teamEvaluation.givers[0].status.status == 'Submitted'"
                                                    class="tableStatusTag tableStatusTag-success"
                                                >
                                                    Submitted
                                                </div>
                                                <div class="marginTop10">{{ teamData.teamEvaluation.givers[0].status.tally }}</div>
                                            </td>
                                            <td class="wordBreakWord whiteSpacePreWrap" v-html="teamData.teamEvaluation.givers[0].response"></td>
                                            <td class="textAlignCenter" v-html="getSentiment(teamData.teamEvaluation.givers[0].response)"></td>
                                        </template>
                                    </tr>
                                    <template v-if="teamData.teamEvaluation.givers.length > 1">
                                        <template v-for="(giver, giver_idx) in teamData.teamEvaluation.givers.slice(1)">
                                            <tr class="thisRow">
                                                <td>
                                                    <span class="whiteSpacePreWrap" v-html="giver.giver"></span>
                                                </td>
                                                <td>
                                                    <div v-if="giver.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">Timed Out</div>
                                                    <div v-if="giver.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                        Not Started
                                                    </div>
                                                    <div v-if="giver.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">Ongoing</div>
                                                    <div v-if="giver.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">Submitted</div>
                                                    <div class="marginTop10">{{ giver.status.tally }}</div>
                                                </td>
                                                <td class="wordBreakWord whiteSpacePreWrap" v-html="giver.response"></td>
                                                <td class="textAlignCenter" v-html="getSentiment(giver.response)"></td>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </template>
                    </div>

                    <div id="evaOnInstructor" :class="{ active: tab == 'evaOnInstructor' }" class="tab-pane fade in">
                        <div v-if="!(questionSelection && instructorSelection)" class="fontItalic">
                            Choose a question and instructor above and press "Search".
                        </div>

                        <div v-if="questionSelection && instructorSelection" class="flexSpaceBetween alignBaseline">
                            <div class="flexOnly alignFlexStart">
                                <button
                                    v-if="instructorData.question.question"
                                    class="btn gradient btn-default marginRight10"
                                    :aria-label="instructorData.question.questionNo"
                                    @click.prevent="openPreviewQuestionModal(instructorData.question)"
                                >
                                    {{ instructorData.question.questionNo }}<i class="fas fa-info-circle marginLeft8" aria-hidden="true"></i>
                                </button>
                                <span class="question displayInlineBlock" v-html="instructorData.question.question"></span>
                            </div>
                            <div v-if="instructorData.question.isRequired" class="statusTag statusTagDanger fontSize12 padding5">Required</div>
                        </div>

                        <template v-if="instructorData.question.type == 'rating' && questionSelection && instructorSelection">
                            <div class="table-responsive newEvaluationTable border1pxGrey marginTop20">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="width150px">RECEIVER</th>
                                            <th class="width150px">GIVER</th>
                                            <th class="width150px">GIVER STATUS</th>
                                            <th>Scale</th>
                                            <th>Label</th>
                                            <th v-if="ratingHaveDescription">Description</th>
                                            <th>AVG</th>
                                            <th>INSTRUCTORS AVG</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="thisRow">
                                            <td :rowspan="instructorData.instructorEvaluation.givers.length">
                                                {{ instructorData.instructorEvaluation.receiver }}
                                            </td>
                                            <template v-if="instructorData.instructorEvaluation.givers.length != 0">
                                                <td>
                                                    {{ instructorData.instructorEvaluation.givers[0].giver }}
                                                </td>
                                                <td>
                                                    <div
                                                        v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Timed Out'"
                                                        class="tableStatusTag tableStatusTag-danger"
                                                    >
                                                        Timed Out
                                                    </div>
                                                    <div
                                                        v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Not Started'"
                                                        class="tableStatusTag tableStatusTag-danger"
                                                    >
                                                        Not Started
                                                    </div>
                                                    <div
                                                        v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Ongoing'"
                                                        class="tableStatusTag tableStatusTag-warning"
                                                    >
                                                        Ongoing
                                                    </div>
                                                    <div
                                                        v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Submitted'"
                                                        class="tableStatusTag tableStatusTag-success"
                                                    >
                                                        Submitted
                                                    </div>
                                                    <div class="marginTop10">{{ instructorData.instructorEvaluation.givers[0].status.tally }}</div>
                                                </td>
                                                <td>
                                                    {{ lookForInstructorWeights(0) }}
                                                </td>
                                                <td>
                                                    {{ lookForInstructorLabels(0) }}
                                                </td>
                                                <td v-if="ratingHaveDescription">
                                                    {{ lookForInstructorRubrics(0) }}
                                                </td>
                                                <td :rowspan="instructorData.instructorEvaluation.givers.length">
                                                    {{ instructorData.instructorEvaluation.instructorAvg }}
                                                </td>
                                                <td :rowspan="instructorData.instructorEvaluation.givers.length">
                                                    {{ instructorData.instructorEvaluation.instructorsMean }}
                                                </td>
                                            </template>
                                        </tr>
                                        <template v-if="instructorData.instructorEvaluation.givers.length > 1">
                                            <template v-for="(giver, giver_idx) in instructorData.instructorEvaluation.givers.slice(1)">
                                                <tr>
                                                    <td>
                                                        {{ giver.giver }}
                                                    </td>
                                                    <td>
                                                        <div v-if="giver.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">
                                                            Timed Out
                                                        </div>
                                                        <div v-if="giver.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                            Not Started
                                                        </div>
                                                        <div v-if="giver.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">Ongoing</div>
                                                        <div v-if="giver.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">
                                                            Submitted
                                                        </div>
                                                        <div class="marginTop10">{{ giver.status.tally }}</div>
                                                    </td>
                                                    <td>
                                                        {{ lookForInstructorWeights(giver_idx + 1) }}
                                                    </td>
                                                    <td>
                                                        {{ lookForInstructorLabels(giver_idx + 1) }}
                                                    </td>
                                                    <td v-if="ratingHaveDescription">
                                                        {{ lookForInstructorRubrics(giver_idx + 1) }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>

                        <template v-if="instructorData.question.type == 'openended' && questionSelection && instructorSelection">
                            <table class="table marginTop20">
                                <thead>
                                    <tr>
                                        <th class="width150px">Receiver</th>
                                        <th class="width150px">Giver</th>
                                        <th class="width150px">Giver Status</th>
                                        <th>Evaluation Given</th>
                                        <th class="width266px minWidth266px maxWidth266px">Sentiment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr></tr>
                                    <tr class="thisRow">
                                        <td :rowspan="instructorData.instructorEvaluation.givers.length">
                                            {{ instructorData.instructorEvaluation.receiver }}
                                        </td>
                                        <template v-if="instructorData.instructorEvaluation.givers.length != 0">
                                            <td>
                                                {{ instructorData.instructorEvaluation.givers[0].giver }}
                                            </td>
                                            <td>
                                                <div
                                                    v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Timed Out'"
                                                    class="tableStatusTag tableStatusTag-danger"
                                                >
                                                    Timed Out
                                                </div>
                                                <div
                                                    v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Not Started'"
                                                    class="tableStatusTag tableStatusTag-danger"
                                                >
                                                    Not Started
                                                </div>
                                                <div
                                                    v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Ongoing'"
                                                    class="tableStatusTag tableStatusTag-warning"
                                                >
                                                    Ongoing
                                                </div>
                                                <div
                                                    v-if="instructorData.instructorEvaluation.givers[0].status.status == 'Submitted'"
                                                    class="tableStatusTag tableStatusTag-success"
                                                >
                                                    Submitted
                                                </div>
                                                <div class="marginTop10">{{ instructorData.instructorEvaluation.givers[0].status.tally }}</div>
                                            </td>
                                            <td class="wordBreakWord whiteSpacePreWrap" v-html="instructorData.instructorEvaluation.givers[0].response"></td>
                                            <td class="textAlignCenter" v-html="getSentiment(instructorData.instructorEvaluation.givers[0].response)"></td>
                                        </template>
                                    </tr>
                                    <template v-if="instructorData.instructorEvaluation.givers.length > 1">
                                        <template v-for="(giver, giver_idx) in instructorData.instructorEvaluation.givers.slice(1)">
                                            <tr class="thisRow">
                                                <td>
                                                    <span class="whiteSpacePreWrap" v-html="giver.giver"></span>
                                                </td>
                                                <td>
                                                    <div v-if="giver.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">Timed Out</div>
                                                    <div v-if="giver.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                        Not Started
                                                    </div>
                                                    <div v-if="giver.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">Ongoing</div>
                                                    <div v-if="giver.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">Submitted</div>
                                                    <div class="marginTop10">{{ giver.status.tally }}</div>
                                                </td>
                                                <td class="wordBreakWord whiteSpacePreWrap" v-html="giver.response"></td>
                                                <td class="textAlignCenter" v-html="getSentiment(giver.response)"></td>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </template>
                    </div>

                    <div id="michelsenMethod" :class="{ active: tab == 'michelsenMethod' }" class="tab-pane fade in">
                        <div v-if="michelsenData.michelsenPoints.length != 0" class="table-responsive newEvaluationTable border1pxGrey">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th rowspan="2" class="width120px minWidth120px maxWidth120px">Total Points Received By</th>
                                        <th rowspan="2" class="width150px">Status</th>
                                        <th :colspan="michelsenData.michelsenPoints.length">Total Points Received From</th>
                                        <th rowspan="2" class="width73px minWidth73px maxWidth73px">Total</th>
                                        <th rowspan="2" class="width73px minWidth73px maxWidth73px">Avg</th>
                                        <th rowspan="2" class="width73px minWidth73px maxWidth73px">Team Avg</th>
                                        <th rowspan="2" class="minWidth118px">
                                            <span class="flex align-items" aria-label="Peer Assessment Factor Beta">
                                                PAF
                                                <a
                                                    v-tooltip="'Peer Assessment Factor Information'"
                                                    href="https://help.intedashboard.com/how-to-interpret-peer-assessment-factor-paf"
                                                    class="marginLeft5"
                                                    target="_blank"
                                                >
                                                    <i class="fa fa-info-circle" aria-label="Peer Assessment Factor Information" />
                                                </a>

                                                <span class="badge badge-pill badge-info betaBadge marginLeft5"> BETA </span>
                                            </span>
                                        </th>
                                        <th rowspan="2" class="minWidth125px">
                                            <span class="flex align-items" aria-label="Self Assessment Over Peer Assessment Beta">
                                                SAPA

                                                <a
                                                    v-tooltip="'Self Assessment Over Peer Assessment Information'"
                                                    href="https://help.intedashboard.com/use-of-sapa-self-assessment-over-peer-assessment"
                                                    class="marginLeft5"
                                                    target="_blank"
                                                >
                                                    <i class="fa fa-info-circle" aria-label="Self Assessment Over Peer Assessment Information" />
                                                </a>

                                                <span class="badge badge-pill badge-info betaBadge marginLeft5"> BETA </span>
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <template v-for="(individual, idx) in michelsenData.michelsenPoints">
                                            <th v-tooltip="individual.receiver" class="whiteSpaceNowrap" tabindex="0">
                                                {{ truncate(individual.receiver, 8) }}
                                            </th>
                                        </template>
                                    </tr>
                                </thead>

                                <tbody>
                                    <template v-for="(individual, idx) in michelsenData.michelsenPoints.slice(0, 1)">
                                        <tr>
                                            <td>
                                                <span
                                                    v-if="checkPAFSAPA(individual)"
                                                    v-tooltip="'PAF not in the range of 0.75 - 1.5 or SAPA is not 1'"
                                                    class="marginRight8"
                                                    tabindex="0"
                                                    role="button"
                                                    aria-label="PAF not in the range of 0.75 - 1.5 or SAPA is not 1"
                                                >
                                                    <i class="fa fa-flag important" aria-hidden="true" />
                                                </span>
                                                {{ individual.receiver }}
                                            </td>

                                            <td>
                                                <div v-if="individual.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">Timed Out</div>
                                                <div v-if="individual.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                    Not Started
                                                </div>
                                                <div v-if="individual.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">Ongoing</div>
                                                <div v-if="individual.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">
                                                    Submitted
                                                </div>
                                                <div v-if="individual.status.tally" class="marginTop10">{{ individual.status.tally }}</div>
                                            </td>

                                            <template v-for="(giver, giver_idx) in individual.givers">
                                                <td :class="{ disabled: michelsenData.michelsenPoints[giver_idx].receiver == individual.receiver }">
                                                    {{ lookForPoint(idx, michelsenData.michelsenPoints[giver_idx].receiver) }}
                                                </td>
                                            </template>

                                            <td>
                                                {{ totalPoint(idx) }}
                                            </td>

                                            <td>
                                                {{ individual.receiverAvg.toFixed(2) }}
                                            </td>

                                            <template v-if="individual.givers.length != 0">
                                                <td :rowspan="individual.givers.length">
                                                    {{ individual.teamMean.toFixed(2) }}
                                                </td>
                                            </template>
                                            <td
                                                :class="{
                                                    important: individual.paf > 1.5 || individual.paf < 0.75,
                                                }"
                                            >
                                                {{ individual.paf }}
                                            </td>
                                            <td
                                                :class="{
                                                    disabled: !testObj.settings.allowToGiveThemselvesPoints,
                                                    important: individual.sapa != '-' && individual.sapa != 1,
                                                }"
                                            >
                                                {{ individual.sapa }}
                                            </td>
                                        </tr>
                                    </template>

                                    <template v-for="(individual, idx) in michelsenData.michelsenPoints.slice(1)">
                                        <tr class="notFirstRow">
                                            <td>
                                                {{ individual.receiver }}
                                            </td>

                                            <td>
                                                <div v-if="individual.status.status == 'Timed Out'" class="tableStatusTag tableStatusTag-danger">Timed Out</div>
                                                <div v-if="individual.status.status == 'Not Started'" class="tableStatusTag tableStatusTag-danger">
                                                    Not Started
                                                </div>
                                                <div v-if="individual.status.status == 'Ongoing'" class="tableStatusTag tableStatusTag-warning">Ongoing</div>
                                                <div v-if="individual.status.status == 'Submitted'" class="tableStatusTag tableStatusTag-success">
                                                    Submitted
                                                </div>
                                                <div v-if="individual.status.tally" class="marginTop10">{{ individual.status.tally }}</div>
                                            </td>

                                            <template v-for="(giver, giver_idx) in individual.givers">
                                                <td :class="{ disabled: michelsenData.michelsenPoints[giver_idx].receiver == individual.receiver }">
                                                    {{ lookForPoint(idx + 1, michelsenData.michelsenPoints[giver_idx].receiver) }}
                                                </td>
                                            </template>

                                            <td>
                                                {{ totalPoint(idx + 1) }}
                                            </td>

                                            <td>
                                                {{ individual.receiverAvg.toFixed(2) }}
                                            </td>
                                            <td>{{ individual.paf }}</td>
                                            <td :class="{ disabled: !testObj.settings.allowToGiveThemselvesPoints }">{{ individual.sapa }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>

        <div
            id="evaluationAnalysisQuestionInfoModal"
            class="modal default-modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="evaluationAnalysisQuestionInfoModal-title"
        >
            <div
                class="modal-dialog"
                :class="{
                    'maxWidth100 widthAuto paddingLeft20 paddingRight20': previewQuestion.type == 'rating' && !isMobileView,
                }"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>

                        <h2 id="evaluationAnalysisQuestionInfoModal-title" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Question
                        </h2>
                    </div>
                    <div class="modal-body">
                        <question-display :question="previewQuestion"></question-display>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: true,
            tab: '',
            evaluationDetailedView: false,
            individualData: {
                question: {},
                individualEvaluation: { givers: [], status: {} },
            },
            teamData: { question: {}, teamEvaluation: { givers: [] } },
            instructorData: { question: {}, instructorEvaluation: { givers: [] } },
            michelsenData: { question: {}, michelsenPoints: [] },

            teams: [],
            instructors: [],
            questions: [],
            teamSelection: '',
            questionSelection: '',
            instructorSelection: '',
            previewQuestion: {},
        };
    },
    computed: {
        canSearch() {
            if (this.tab == 'evaIndividual') {
                if (this.questionSelection != '' && this.teamSelection != '') {
                    return true;
                }
            } else if (this.tab == 'evaOnTeam') {
                if (this.questionSelection != '' && this.teamSelection != '') {
                    return true;
                }
            } else if (this.tab == 'evaOnInstructor') {
                if (this.questionSelection != '' && this.instructorSelection != '') {
                    return true;
                }
            } else if (this.tab == 'michelsenMethod') {
                if (this.teamSelection != '') {
                    return true;
                }
            }
            return false;
        },
        ratingHaveDescription() {
            if (this.tab == 'evaIndividual') {
                // console.log(this.individualData.question.type);
                if (this.individualData.question.type == 'rating') {
                    for (var i = 0; i < this.individualData.question.ratingSettings.length; i++) {
                        if (this.individualData.question.ratingSettings[i].rubric) {
                            return true;
                        }
                    }
                }
            } else if (this.tab == 'evaOnTeam') {
                // console.log(this.teamData.question.type);
                if (this.teamData.question.type == 'rating') {
                    for (var i = 0; i < this.teamData.question.ratingSettings.length; i++) {
                        if (this.teamData.question.ratingSettings[i].rubric) {
                            return true;
                        }
                    }
                }
            } else if (this.tab == 'evaOnInstructor') {
                // console.log(this.instructorData.question.type);
                if (this.instructorData.question.type == 'rating') {
                    for (var i = 0; i < this.instructorData.question.ratingSettings.length; i++) {
                        if (this.instructorData.question.ratingSettings[i].rubric) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },
    },
    watch: {
        tab: {
            handler: function () {
                let target = 'peer';
                if (this.tab == 'evaOnTeam') {
                    target = 'team';
                } else if (this.tab == 'evaOnInstructor') {
                    target = 'instructor';
                }
                var that = this;
                axios.get(`/tests/${this.testObj.uuid}/questions?target=${target}`).then(function (response) {
                    that.questions = response.data;
                });
                this.teamSelection = '';
                this.questionSelection = '';
                this.instructorSelection = '';
            },
            deep: true,
        },
        'testObj.status': {
            handler: function () {
                if (['completed', 'ended'].includes(this.testObj.status)) {
                    this.fetch(); //refetch report
                }
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {
        let tab = '';
        if (this.testObj.evaluationSettings.evaluateThemselves || this.testObj.evaluationSettings.evaluateTeammates) {
            tab = 'evaIndividual';
        } else if (this.testObj.evaluationSettings.evaluateTeam) {
            tab = 'evaOnTeam';
        } else if (this.testObj.evaluationSettings.evaluateInstructor) {
            tab = 'evaOnInstructor';
        } else if (this.testObj.settings.enableMichaelsenPointDistribution) {
            tab = 'michelsenMethod';
        }
        this.tab = _.get(this, '$route.query.tab', tab);
    },
    methods: {
        openPreviewQuestionModal(question) {
            this.previewQuestion = question;
            this.previewQuestion.questionIsHTML = true;
            $('#evaluationAnalysisQuestionInfoModal').modal('show');
        },
        init() {
            var that = this;
            axios.all([axios.get(`/tests/${this.testObj.uuid}/teams`), axios.get(`/tests/${this.testObj.uuid}/instructors`)]).then(
                axios.spread(function (teams, instructors) {
                    that.teams = teams.data;
                    that.instructors = instructors.data;
                })
            );
            //axios.all([])
        },
        fetch() {
            if (this.tab == 'evaIndividual') {
                this.fetchIndividualEvaluation();
            } else if (this.tab == 'evaOnTeam') {
                this.fetchTeamEvaluation();
            } else if (this.tab == 'evaOnInstructor') {
                this.fetchInstructorEvaluation();
            } else if (this.tab == 'michelsenMethod') {
                this.fetchMichelsenEvaluation();
            }
        },
        fetchMichelsenEvaluation() {
            var that = this;
            axios.post(`/tests/${this.testObj.uuid}/dashboard/evaluation/michelsen-method`, { teamUuid: this.teamSelection }).then(function (response) {
                that.michelsenData = response.data.data;
            });
        },
        fetchIndividualEvaluation() {
            var that = this;
            axios
                .post(`/tests/${this.testObj.uuid}/dashboard/evaluation/individual`, {
                    questionUuid: this.questionSelection,
                    teamUuid: this.teamSelection,
                })
                .then(function (response) {
                    that.individualData = response.data.data;
                });
        },
        fetchTeamEvaluation() {
            var that = this;
            axios
                .post(`/tests/${this.testObj.uuid}/dashboard/evaluation/team`, {
                    questionUuid: this.questionSelection,
                    teamUuid: this.teamSelection,
                })
                .then(function (response) {
                    that.teamData = response.data.data;
                });
        },
        fetchInstructorEvaluation() {
            var that = this;
            axios
                .post(`/tests/${this.testObj.uuid}/dashboard/evaluation/instructor`, {
                    questionUuid: this.questionSelection,
                    userUuid: this.instructorSelection,
                })
                .then(function (response) {
                    that.instructorData = response.data.data;
                });
        },
        lookForPoint(idx, receiver) {
            var data = this.michelsenData.michelsenPoints[idx].givers;
            var index;

            index = data.findIndex((x) => x.giver === receiver);
            return data[index].point;
        },
        totalPoint(idx) {
            var data = this.michelsenData.michelsenPoints[idx].givers;
            var sum = 0;

            data.forEach(function (value) {
                if (value.point == '-' || value.point == '') {
                    sum += parseFloat(0);
                } else {
                    sum += parseFloat(value.point);
                }
            });

            return sum;
        },
        lookForWeight(idx, receiver) {
            var data = this.individualData.individualEvaluation[idx].givers;
            var index;
            var str;

            index = data.findIndex((x) => x.giver === receiver);
            str = data[index].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.slice(0, str.indexOf('('));
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForTeamWeights(idx) {
            var data = this.teamData.teamEvaluation.givers;
            var str;

            str = data[idx].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.slice(0, str.indexOf('('));
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForInstructorWeights(idx) {
            var data = this.instructorData.instructorEvaluation.givers;
            var str;

            str = data[idx].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.slice(0, str.indexOf('('));
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForLabel(idx, receiver) {
            var data = this.individualData.individualEvaluation[idx].givers;
            var index;
            var str;

            index = data.findIndex((x) => x.giver === receiver);
            str = data[index].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.match(/\(([^)]+)\)/)[1];
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForTeamLabels(idx) {
            var data = this.teamData.teamEvaluation.givers;
            var str;

            str = data[idx].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.match(/\(([^)]+)\)/)[1];
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForInstructorLabels(idx) {
            var data = this.instructorData.instructorEvaluation.givers;
            var str;

            str = data[idx].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.match(/\(([^)]+)\)/)[1];
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForRubric(idx, receiver) {
            var data = this.individualData.individualEvaluation[idx].givers;
            var index;
            var str;

            var descriptionIndex;

            index = data.findIndex((x) => x.giver === receiver);
            str = data[index].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.slice(0, str.indexOf('('));
                    }
                }
            }

            if (str != null) {
                if (str != '-') {
                    descriptionIndex = this.individualData.question.ratingSettings.findIndex((x) => x.weight === parseFloat(str));
                    let rubric = _.get(
                        this.individualData.question,
                        `ratingSettings.${descriptionIndex}
              .rubric`
                    );
                    if (rubric == null) {
                        str = '-';
                    } else {
                        str = rubric;
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForTeamRubrics(idx, receiver) {
            var data = this.teamData.teamEvaluation.givers;
            var str;

            var descriptionIndex;

            str = data[idx].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.slice(0, str.indexOf('('));
                    }
                }
            }

            if (str != null) {
                if (str != '-') {
                    descriptionIndex = this.teamData.question.ratingSettings.findIndex((x) => x.weight === parseFloat(str));
                    let rubric = _.get(
                        this.teamData.question,
                        `ratingSettings.${descriptionIndex}
              .rubric`
                    );
                    if (rubric == null) {
                        str = '-';
                    } else {
                        str = rubric;
                    }
                }
            } else {
                str = '-';
            }

            return str;
        },
        lookForInstructorRubrics(idx, receiver) {
            var data = this.instructorData.instructorEvaluation.givers;
            var str;

            var descriptionIndex;

            str = data[idx].response;

            if (str != null) {
                if (str != '-') {
                    if ($.isNumeric(str) == false) {
                        str = str.slice(0, str.indexOf('('));
                    }
                }
            }

            if (str != null) {
                if (str != '-') {
                    descriptionIndex = this.instructorData.question.ratingSettings.findIndex((x) => x.weight === parseFloat(str));
                    let rubric = _.get(
                        this.instructorData.question,
                        `ratingSettings.${descriptionIndex}
              .rubric`
                    );
                    if (rubric == null) {
                        str = '-';
                    } else {
                        str = rubric;
                    }
                }
            } else {
                str = '-';
            }
            return str;
        },
        totalEvaluationScale(idx) {
            var data = this.individualData.individualEvaluation[idx].givers;
            var sum = 0;
            var str;

            data.forEach(function (value) {
                if (value.response == '-' || value.response == '' || value.response == null) {
                    sum += parseFloat(0);
                } else {
                    str = value.response;

                    if ($.isNumeric(str) == false) {
                        sum += parseFloat(str.slice(0, str.indexOf('(')));
                    } else {
                        sum += parseFloat(str);
                    }
                }
            });

            return sum;
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        checkPAFSAPA(student) {
            if (student.paf != '-') {
                if (student.paf > 1.5 || student.paf < 0.75) {
                    return true;
                }
            } else if (student.sapa != '-' && this.testObj.settings.allowToGiveThemselvesPoints) {
                if (student.sapa != 1) {
                    return true;
                }
            }
        },
    },
    components: {
        'question-display': require(`./../../../../../../questions/partials/question-display.vue`).default,
    },
};
</script>
