<template>
    <div
        ref="modal"
        class="modal default-modal acceptNewAnsModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="acceptNewAnsModal-title"
        aria-modal="true"
        hidden
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="acceptNewAnsModal-title" class="modal-title">Update Correct Answer</h2>
                </div>
                <div class="modal-body newRadioField">
                    <!-- <div v-if="step == 3" class="textAlignCenter">
                        <img src="/img/warning.png" class="width50px" alt=" " />
                        <p class="important">
                            <b> The changes will affect all sections' activity. </b>
                        </p>
                        <p class="important">
                            <b>
                                The new
                                <template v-if="correctAnsMode != 'Change the correct answer'"> additional </template> correct answer
                            </b>
                        </p>
                        <p class="important">
                            <b> will be reflected in the same activity. </b>
                        </p>
                    </div> -->

                    <div class="stepper-container">
                        <div class="stepper-wrapper justifyCenter">
                            <button
                                class="stepper-item justifyContentFlexStart"
                                :class="{
                                    active: step == 1,
                                    completed: step > 1,
                                }"
                                @click.prevent="step = 1"
                            >
                                <template v-if="step == 1">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 1</div>
                                </template>
                                <template v-else-if="step > 1">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 1</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">1</div>
                                </template>

                                <label> Selection</label>
                            </button>
                            <button
                                class="stepper-item justifyContentFlexStart"
                                :class="{
                                    active: step == 2,
                                    completed: step > 2,
                                }"
                                @click.prevent="step = 2"
                            >
                                <template v-if="step == 2">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 2</div>
                                </template>
                                <template v-else-if="step > 2">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 2</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">2</div>
                                </template>

                                <label>
                                    <template v-if="correctAnsMode == 'Change the correct answer'"> Change the correct answer </template>
                                    <template v-else> Accept additional correct answer </template>
                                </label>
                            </button>
                            <button
                                v-tooltip="
                                    newGroupAnswers.length == 0
                                        ? correctAnsMode == 'Change the correct answer'
                                            ? 'Please select new answer'
                                            : 'Please select at least 1 new answer'
                                        : ''
                                "
                                class="stepper-item justifyContentFlexStart"
                                :class="{
                                    active: step == 3,
                                    completed: step > 3,
                                    'disabled hasToolTip': newGroupAnswers.length == 0,
                                }"
                                :aria-label="
                                    newGroupAnswers.length == 0
                                        ? correctAnsMode == 'Change the correct answer'
                                            ? 'step 3 confirmation disabled Please select new answer'
                                            : 'step 3 confirmation disabled Please select at least 1 new answer'
                                        : 'step 3 confirmation'
                                "
                                @click.prevent="newGroupAnswers.length != 0 ? (step = 3) : ''"
                            >
                                <template v-if="step == 3">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 3</div>
                                </template>
                                <template v-else-if="step > 3">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 3</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">3</div>
                                </template>

                                <label> Confirmation </label>
                            </button>
                        </div>
                    </div>

                    <div v-if="[1, 2].includes(step)" class="alert alert-warning marginBottom30" role="alert">
                        <i class="fa fa-circle-exclamation marginRight8" aria-hidden="true"></i>
                        <p>You can only use this function <b>once</b> per question.</p>
                    </div>

                    <template v-if="step == 1">
                        <fieldset aria-required="true" role="radiogroup">
                            <legend>
                                <b>What would you like to do?</b>
                            </legend>

                            <template v-for="(type, option_idx) in correctAnswerType">
                                <div
                                    class="radioField flex"
                                    :class="{
                                        marginTop20: option_idx != 0,
                                    }"
                                >
                                    <input
                                        :id="'updateCorrectAnsMode-' + type.shortForm"
                                        v-model="correctAnsMode"
                                        name="changeCorrectAnswerType"
                                        type="radio"
                                        :value="type.value"
                                        :checked="type.value === correctAnsMode"
                                        :tabindex="type.disabled ? 0 : null"
                                        :aria-disabled="type.disabled"
                                        @click="type.disabled ? $event.preventDefault() : null"
                                        @keydown.space="type.disabled ? $event.preventDefault() : null"
                                    />

                                    <label :for="'updateCorrectAnsMode-' + type.shortForm" class="paddingTop5 d-block">
                                        {{ type.value }}
                                        <br />
                                        <template v-if="type.disabled">
                                            <i class="greyText">({{ type.reason }})</i>
                                        </template>
                                    </label>
                                </div>
                            </template>
                        </fieldset>
                    </template>
                    <template v-else-if="step == 2">
                        <h3>
                            {{ correctAnsMode }}
                        </h3>
                        <div class="marginBottom20 collapsingQuestion">
                            <button
                                class="btn"
                                :data-target="'#viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-acceptNewAnswer'"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-acceptNewAnswer'"
                            >
                                Question
                                {{ getQuestionNo(question.group - 1, question.order - 1) }} Question Stem
                            </button>

                            <div :id="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-acceptNewAnswer'" class="collapse padding10">
                                <div class="question">
                                    <kr-math :input="question.question" :safe="!question.questionIsHTML" />
                                </div>
                            </div>
                        </div>

                        <fieldset>
                            <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} Answer Choices</legend>

                            <template v-for="(option, option_idx) in question.optionKeys">
                                <div
                                    class="radioField flex"
                                    :class="{
                                        'discussionOption correct': option.isCorrect,
                                        'discussionOption newCorrect': optionCorrect(question.id, option.key),
                                        'padding10 paddingLeft32': !(option.isCorrect || optionCorrect(question.id, option.key)),
                                        marginTop20: option_idx != 0,
                                    }"
                                >
                                    <i v-if="option.isCorrect || optionCorrect(question.id, option.key)" class="fa fa-check-circle" />
                                    <input
                                        v-else
                                        :id="question.uuid + '-updateCorrectAns-' + option.key"
                                        v-model="newGroupAnswers"
                                        :name="question.uuid"
                                        :type="correctAnsMode == 'Change the correct answer' ? 'radio' : 'checkbox'"
                                        :value="option.key"
                                    />

                                    <label :for="question.uuid + '-updateCorrectAns-' + option.key" class="paddingTop5">
                                        <span class="withOptionKey"> {{ option.key }}. </span>
                                        <span class="question">
                                            <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                                        </span>
                                    </label>
                                </div>
                            </template>
                        </fieldset>
                    </template>
                    <template v-else-if="step == 3">
                        <h4 class="textAlignCenter">
                            You are about to
                            <template v-if="correctAnsMode == 'Change the correct answer'"> change the correct answer </template>
                            <template v-else> accept additional correct answer/s </template>
                            for
                        </h4>
                        <h4 class="textAlignCenter">
                            for <b> Question {{ getQuestionNo(question.group - 1, question.order - 1) }} </b> in <b> {{ test.name }} </b>
                        </h4>
                        <br />

                        <template v-for="(option, option_idx) in question.optionKeys" v-if="correctAnsMode == 'Change the correct answer'">
                            <template v-if="option.isCorrect">
                                <div class="collapsingQuestion textAlignLeft marginBottom20">
                                    <button
                                        class="btn"
                                        :data-target="'#viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-previousCorrectAnswer'"
                                        data-toggle="collapse"
                                        aria-expanded="false"
                                        :aria-controls="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-previousCorrectAnswer'"
                                    >
                                        View Previous Correct Answer Option {{ option.key }}
                                    </button>

                                    <div
                                        :id="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-previousCorrectAnswer'"
                                        class="collapse padding10"
                                    >
                                        <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                                    </div>
                                </div>
                            </template>
                        </template>
                        <div
                            v-for="(answer, idx) in newGroupAnswers"
                            class="collapsingQuestion textAlignLeft newCorrectOption"
                            :class="{ marginTop20: idx != 0 }"
                        >
                            <button
                                class="btn"
                                :data-target="'#viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-updateCorrectAns-' + idx"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-updateCorrectAns-' + idx"
                            >
                                View
                                <template v-if="correctAnsMode == 'Change the correct answer'"> New </template>
                                <template v-else> Additional </template>
                                Correct Answer Option
                                {{ answer }}
                            </button>

                            <div
                                :id="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-updateCorrectAns-' + idx"
                                class="collapse padding10"
                            >
                                <div v-for="(option, option_idx) in question.optionKeys">
                                    <template v-if="option.key == answer">
                                        <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                                    </template>
                                </div>
                            </div>
                        </div>

                        <br />
                        <p v-if="!['not started'].includes(test.status)" class="textAlignCenter">All students results will be recalculated accordingly</p>
                    </template>
                </div>

                <div class="modal-footer">
                    <button v-if="step == 1" class="btn btn-outline-default" data-dismiss="modal" @click="closeModal">Cancel</button>
                    <button v-if="step == 2 || step == 3" class="btn btn-outline-default" @click.prevent="step = step - 1">Back</button>

                    <button v-if="step == 1" class="btn btn-primary" @click.prevent="step = 2">Next Step</button>
                    <button
                        v-if="step == 2"
                        v-tooltip="
                            newGroupAnswers.length == 0
                                ? correctAnsMode == 'Change the correct answer'
                                    ? 'Please select new answer'
                                    : 'Please select at least 1 new answer'
                                : ''
                        "
                        class="btn btn-primary"
                        :class="{
                            'disabled hasToolTip': newGroupAnswers.length == 0,
                        }"
                        :aria-label="newGroupAnswers.length == 0 ? 'Next Step disabled' : 'Next Step'"
                        @click.prevent="newGroupAnswers.length != 0 ? (step = 3) : ''"
                    >
                        Next Step
                    </button>
                    <button v-if="step == 3" class="btn btn-success" @click.prevent="submit">
                        <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />
                        Save
                        <template v-if="correctAnsMode == 'Change the correct answer'"> New </template>
                        <template v-else>Additional </template>
                        Answer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['accepted']);
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick, watch } from 'vue';

const modal = ref(null);
const question = ref({});
const test = ref({});
const activitiesStatus = ref({});
const newGroupAnswers = ref([]);
const step = ref(1);

const correctAnsMode = ref('');

const correctAnswerType = ref([
    {
        shortForm: 'changeAnswer',
        value: 'Change the correct answer',
        disabled: false,
        reason: null,
    },
    {
        shortForm: 'addAnswer',
        value: 'Accept additional correct answer',
        disabled: false,
        reason: null,
    },
]);

const openModal = () => {
    nextTick(() => {
        $(modal.value).modal('show');
    });
};

const closeModal = () => {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
};

const accept = (t = {}, q = {}, as = {}) => {
    test.value = t;
    question.value = q;
    activitiesStatus.value = as;
    step.value = 1;
    newGroupAnswers.value = [];
    correctAnsMode.value = 'Change the correct answer';

    openModal();
    checkStatus();
};

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += test.value.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};

const optionCorrect = (questionId, key) => {
    if (['ended', 'completed'].includes(test.value.status) && question.value.type == 'mcqs') {
        for (var i = 0; i < test.value.acceptedNewAnswers.length; i++) {
            if (test.value.acceptedNewAnswers[i].activityQuestionId == questionId) {
                for (var j = 0; j < test.value.acceptedNewAnswers[i].acceptedAnswers.length; j++) {
                    if (test.value.acceptedNewAnswers[i].acceptedAnswers[j] == key) {
                        return true;
                    }
                }
            }
        }
    }
};

const submit = () => {
    if (correctAnsMode.value == 'Change the correct answer') {
        axios
            .post('activities/' + test.value.activityUuid + '/change-correct-answer', {
                activityQuestionUuid: question.value.uuid,
                newCorrectAnswerOption: newGroupAnswers.value[0],
            })
            .then(function (response) {
                closeModal();
                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Correct answer/s changed',
                });
            })
            .catch(function (errors) {
                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: _.get(errors.response, 'data.message', 'Unable to change correct answer at this moment, please try again later'),
                });
            });
    } else {
        axios
            .post('activities/' + test.value.activityUuid + '/accept-new-answers', {
                activityQuestionUuid: question.value.uuid,
                newCorrectAnswers: newGroupAnswers.value,
            })
            .then(function (response) {
                closeModal();
                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'New answer(s) has been added',
                });
            })
            .catch(function (errors) {
                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: _.get(errors.response, 'data.message', 'Unable to add new correct answer at this moment, please try again later'),
                });
            });
    }
};

const checkStatus = () => {
    for (var i = 0; i < Object.keys(activitiesStatus.value).length; i++) {
        if (activitiesStatus.value[i].studentsWithExtraTime > 0) {
            if (['irat', 'trat'].includes(activitiesStatus.value[i].type)) {
                activitiesStatus.value[i].type = activitiesStatus.value[i].type.toUpperCase();
            }

            return (correctAnswerType.value[1] = {
                ...correctAnswerType.value[1],
                disabled: true,
                reason: `Test still ongoing (Extra Time) in Section ${activitiesStatus.value[i].sectionName} ${activitiesStatus.value[i].type}`,
            });
        }
    }
};

watch(
    () => correctAnsMode.value,
    (newVal) => {
        if (newVal) {
            newGroupAnswers.value = [];
        }
    }
);

defineExpose({ accept });
</script>
