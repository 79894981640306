<template>
    <div class="padding20">
        <div class="hidden-xs hidden-sm marginBottom20 listingSwitch textAlignRight" role="menu">
            <button
                v-tooltip="'Stacked view'"
                type="button"
                role="menuitem"
                class="alignBaseLine"
                :class="{ active: !question.enableStackingColumns }"
                disabled
            >
                <i class="fa-solid fa-layer-group" aria-hidden="true"></i>
            </button>
            <button
                v-tooltip="'Two columns view'"
                type="button"
                role="menuitem"
                class="alignBaseLine"
                :class="{ active: question.enableStackingColumns }"
                disabled
            >
                <i class="fa-solid fa-table-columns" aria-hidden="true"></i>
            </button>
        </div>

        <div
            :class="{
                'flex maxWidth2000px': question.enableStackingColumns,
            }"
        >
            <div
                class="question presentationMode"
                :class="{
                    'col-xs-12 col-md-6 paddingRight10 marginBottom20': question.enableStackingColumns,
                }"
            >
                <kr-math :input="question.question" :safe="!question.questionIsHTML" />
                <template v-if="question.attachments && question.attachments.length != 0">
                    <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
                    <div class="textLink">
                        <div v-for="(file, idx) in question.attachments" class="flex">
                            <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                            <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                        </div>
                    </div>
                </template>
            </div>

            <div
                :class="{
                    'col-xs-12 col-md-6': question.enableStackingColumns,
                    marginTop20: !question.enableStackingColumns,
                }"
            >
                <!-- <template v-for="(option,option_idx) in question.options">
                    <div class="discussionOption flexOnly" :class="{'correct':option.isCorrect&&question.displayAnswer,'padding10 paddingLeft32':!option.isCorrect&&question.displayAnswer}">
                        <i class="fa fa-check-circle" v-if="option.isCorrect&&question.displayAnswer"></i>
                        <i class="fas fa-times-circle" v-if="!option.isCorrect&&question.displayAnswer"></i>
                        <div class="fontBold marginRight5">{{option.key}}</div>
                        <div v-if="option.contentIsHTML" v-html="option.content"></div>
                        <div v-else>{{option.content}}</div>
                    </div>
                </template> -->

                <template v-for="(option, option_idx) in question.optionKeys">
                    <div
                        class="discussionOption"
                        :class="{
                            correct: option.isCorrect && question.displayAnswer,
                            'padding10 paddingLeft32': !option.isCorrect && question.displayAnswer,
                        }"
                    >
                        <div class="flexOnly">
                            <i v-if="option.isCorrect && question.displayAnswer" class="fa fa-check-circle marginRight8" aria-hidden="true" />
                            <i v-if="!option.isCorrect && question.displayAnswer" class="fas fa-times-circle marginRight8" aria-hidden="true" />
                            <div class="fontBold marginRight5">
                                {{ option.key }}
                            </div>
                            <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                        </div>

                        <div v-if="question.displayAnswerStatistics" class="marginTop10">
                            <div class="discussion-bar">
                                <template v-if="option.percent.toFixed(0) >= 10">
                                    <div
                                        class="barProgress"
                                        :style="'width:' + option.percent.toFixed(0) + '%'"
                                        :class="{
                                            'colorGreenBg whiteText': option.isCorrect && question.displayAnswer,
                                            'colorRedBg whiteText': !option.isCorrect && question.displayAnswer,
                                        }"
                                    >
                                        {{ option.percent.toFixed(0) }}%
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-xs-12">{{ option.percent.toFixed(0) }}%</div>
                                </template>
                            </div>
                            <div class="marginTop10 flexOnly hidden-xs hidden-sm">
                                <div class="responseFont col-xs-2 maxWidth100px">
                                    {{ option.countAnswersPerOptionData }}
                                    <template v-if="option.countAnswersPerOptionData <= 1"> Response </template>
                                    <template v-else> Responses </template>
                                </div>
                                <div class="discussion-buttons">
                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                        <template v-if="model.student">
                                            <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown">
                                                <button
                                                    id="popOver"
                                                    class="btn gradient btn-default subBtn marginBottom10"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="model.student.fullname"
                                                >
                                                    {{ truncate(model.student.fullname) }};<i class="far fa-comment-dots marginLeft8" aria-hidden="true" />
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                                    <div>
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                                            <span class="lineHeight08 fontBold"> Elaboration </span>
                                                        </div>
                                                        <div v-if="model.comment">
                                                            <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                            <template v-if="model.comment.length > 400">
                                                                <div
                                                                    class="blue pointer marginTop10"
                                                                    aria-label="View More"
                                                                    @click="openModelInfoModal(model, 'student')"
                                                                >
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div v-else>-</div>
                                                    </div>
                                                    <div class="marginTop20">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-paperclip marginRight5 greyText" />
                                                            <span class="lineHeight08 fontBold"> Attachment(s) </span>
                                                        </div>
                                                        <div>
                                                            <template v-if="model.attachments && model.attachments.length != 0">
                                                                <div v-for="(file, idx) in model.attachments" class="flex maxHeight100px">
                                                                    <span class="marginRight5"> {{ idx + 1 }}. </span>
                                                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">
                                                                        {{ file.filename }}
                                                                    </a>
                                                                </div>
                                                                <template v-if="model.attachments.length > 4">
                                                                    <div
                                                                        class="blue pointer marginTop10"
                                                                        aria-label="View More"
                                                                        @click="openModelInfoModal(model, 'student')"
                                                                    >
                                                                        View More
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </div>
                                                    </div>
                                                    <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                            <span class="lineHeight08 fontBold">Team Members</span>
                                                        </div>
                                                        <div class="maxHeight100px">
                                                            <ul v-for="(student, idx) in model.team.members">
                                                                <li>
                                                                    {{ student.displayName }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <template v-if="model.team.members.length > 4">
                                                            <div class="blue pointer" aria-label="View More" @click="openModelInfoModal(model, 'student')">
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-else>
                                                <button class="btn gradient btn-default subBtn marginBottom10 cursor-norm" :aria-label="model.student.fullname">
                                                    {{ truncate(model.student.fullname) }}
                                                </button>
                                            </template>
                                        </template>

                                        <template v-else-if="model.team">
                                            <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown">
                                                <button
                                                    id="popOver"
                                                    class="btn gradient btn-default subBtn marginBottom10"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="model.team.name"
                                                >
                                                    {{ truncate(model.team.name) }}<i class="far fa-comment-dots marginLeft8" aria-hidden="true" />
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                                    <div>
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                                            <span class="lineHeight08 fontBold">Elaboration</span>
                                                        </div>
                                                        <div v-if="model.comment">
                                                            <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                            <template v-if="model.comment.length > 400">
                                                                <div class="blue pointer marginTop10" aria-label="View More" @click="openModelInfoModal(model)">
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <div v-else>-</div>
                                                    </div>
                                                    <div class="marginTop20">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true" />
                                                            <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                        </div>
                                                        <div>
                                                            <template v-if="model.attachments && model.attachments.length != 0">
                                                                <div v-for="(file, idx) in model.attachments" class="flex maxHeight100px">
                                                                    <span class="marginRight5"> {{ idx + 1 }}. </span>
                                                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">
                                                                        {{ file.filename }}
                                                                    </a>
                                                                </div>
                                                                <template v-if="model.attachments.length > 4">
                                                                    <div
                                                                        class="blue pointer marginTop10"
                                                                        aria-label="View More"
                                                                        @click="openModelInfoModal(model)"
                                                                    >
                                                                        View More
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </div>
                                                    </div>
                                                    <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                            <span class="lineHeight08 fontBold">Team Members</span>
                                                        </div>
                                                        <div class="maxHeight100px">
                                                            <ul v-for="(student, idx) in model.team.members">
                                                                <li>
                                                                    {{ student.displayName }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <template v-if="model.team.members.length > 4">
                                                            <div class="blue pointer" aria-label="View More" @click="openModelInfoModal(model)">View More</div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <template v-else>
                                                <div class="dropdown">
                                                    <button
                                                        id="popOver"
                                                        class="btn gradient btn-default subBtn marginBottom10"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        :aria-label="model.team.name"
                                                    >
                                                        {{ truncate(model.team.name) }}
                                                    </button>
                                                    <div class="dropdown-menu dropdown-disucssion padding10 fontBlack" aria-labelledby="popOver">
                                                        <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                            <div class="flexOnly marginBottom5 align-items">
                                                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                                <span class="lineHeight08 fontBold"> Team Members </span>
                                                            </div>
                                                            <div class="maxHeight100px">
                                                                <ul>
                                                                    <template v-for="(student, idx) in model.team.members">
                                                                        <li>
                                                                            {{ student.displayName }}
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                            <template v-if="model.team.members.length > 4">
                                                                <div class="blue pointer" aria-label="View More" @click="openModelInfoModal(model)">
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <div class="responseFont marginTop10">
                                    {{ option.countAnswersPerOptionData }}
                                    <template v-if="option.countAnswersPerOptionData <= 1"> Response </template>
                                    <template v-else> Responses </template>
                                </div>
                                <div class="discussion-buttons marginTop10">
                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                        <template v-if="model.student">
                                            <template v-if="(model.attachments && model.attachments.length != 0) || model.comment">
                                                <button
                                                    class="btn gradient btn-default subBtn marginBottom10"
                                                    :aria-label="model.student.fullname"
                                                    @click="openModelInfoModal(model, 'student')"
                                                >
                                                    {{ truncate(model.student.fullname) }}<i class="far fa-comment-dots marginLeft8" aria-hidden="true" />
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button class="btn gradient btn-default subBtn marginBottom10 cursor-norm" :aria-label="model.student.fullname">
                                                    {{ truncate(model.student.fullname) }}
                                                </button>
                                            </template>
                                        </template>

                                        <template v-if="model.team">
                                            <template v-if="(model.attachments && model.attachments.length != 0) || model.comment">
                                                <button
                                                    class="btn gradient btn-default subBtn marginBottom10"
                                                    :aria-label="model.team.name"
                                                    @click="openModelInfoModal(model)"
                                                >
                                                    {{ truncate(model.team.name) }}<i class="far fa-comment-dots marginLeft8" aria-hidden="true" />
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    class="btn gradient btn-default subBtn marginBottom10"
                                                    :aria-label="model.team.name"
                                                    @click="openModelInfoModal(model)"
                                                >
                                                    {{ truncate(model.team.name) }}
                                                </button>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <!-- <template v-if="!question.displayAnswerStatistics">
		<template v-if="question.studentAnswered || question.teamsAnswered">
			<div class="mini-panel panel col-xs-12 col-md-10 hidden-xs hidden-sm marginTop20">
				<template v-if="question.studentAnswered">
					<div class="panel-heading flexSpaceBetween">
						<div>SUBMITTED ({{question.studentAnswered.length}})</div>
					<div><a class="link">View All&nbsp;<i class="fa fa-caret-right"></i></a></div>
					</div>
					<div class="panel-body flex">
						<template v-for="(student,student_idx) in question.studentAnswered">
							<div class="btnSections">
								<button class="btn gradient btn-default subBtn">
									Student {{student.fullname}}&nbsp;<i class="fa fa-user"></i>
								</button>
							</div>
						</template>
					</div>
				</template>
				<template v-if="question.teamsAnswered">
					<div class="panel-heading flexSpaceBetween">
						<div>SUBMITTED ({{question.teamsAnswered.length}})</div>
					<div><a class="link">View All&nbsp;<i class="fa fa-caret-right"></i></a></div>
					</div>
					<div class="panel-body flex">
						<template v-for="(team,team_idx) in question.teamsAnswered">
							<div class="btnSections buttonGroup">
								<button class="btn gradient btn-default subBtn" @click="openTeamInfoModal(team)">
									{{team.name}}&nbsp;<i class="fa fa-users"></i>
								</button>
							</div>
						</template>
					</div>
				</template> 
			</div>
			<div class="mini-panel panel hidden-md hidden-lg marginTop20">
				<template v-if="question.studentAnswered">
					<div class="panel-heading flexSpaceBetween">
						<div>SUBMITTED ({{question.studentAnswered.length}})</div>
					<div><a class="link">View All&nbsp;<i class="fa fa-caret-right"></i></a></div>
					</div>
					<div class="panel-body">
						<template v-for="(student,student_idx) in question.studentAnswered">
							<div class="btnSections">
								<button class="btn gradient btn-default subBtn">
									Student {{student.fullname}}&nbsp;<i class="fa fa-user"></i>
								</button>
							</div>
						</template>
					</div>
				</template>
				<template v-if="question.teamsAnswered">
					<div class="panel-heading flexSpaceBetween">
						<div>SUBMITTED ({{question.teamsAnswered.length}})</div>
					<div><a class="link">View All&nbsp;<i class="fa fa-caret-right"></i></a></div>
					</div>
					<div class="panel-body">
						<template v-for="(team,team_idx) in question.teamsAnswered">
							<div class="btnSections">
								<button class="btn gradient btn-default subBtn" @click="openTeamInfoModal(team)">
									{{team.name}}&nbsp;<i class="fa fa-users"></i>
								</button>
							</div>
						</template>
					</div>
				</template> 
			</div>
		</template>
	</template> -->
        <!-- <div class="marginTop20" v-if="question.displayAnswerStatistics">
		<div class="flex">
			<div class="mini-panel panel col-xs-12">
				<div class="panel-heading flexSpaceBetween">
					<div>ANSWER CHOICES</div>
				</div>
				<div class="panel-body flex">
					<table class="barChart">
						<tbody class="body">
							<template v-for="(option,option_idx) in question.optionKeys">
								<tr>
									<div class="percentage" :style="'bottom:'+((option.percent.toFixed(0)/100)*80)+'%'">{{round(option.percent)}}%</div>
									<td class="bars" :class="{'correct':option.isCorrect&&question.displayAnswer,'wrong':!option.isCorrect&&question.displayAnswer,'default':!question.displayAnswer}" :style="'height:'+((option.percent.toFixed(0)/100)*80)+'%;'"></td>
									<td class="record">
										<div class="marginBottom10">Choice {{option.key}}</div>
										<template v-for="(model,model_idx) in option.answersPerOption">
											<template v-if="model.student">
												<div class="btnDiscussionTeams">
													<button class="btn btn-discussionTeams subBtn">
														{{model.student.fullname}}&nbsp;<i class="fa fa-user"></i>
													</button>
												</div>
											</template>
											<template v-if="model.team">
												<div class="btnDiscussionTeams">
													<button class="btn btn-discussionTeams subBtn" @click="openTeamInfoModal(model.team)">
														{{model.team.name}}&nbsp;<i class="fa fa-users"></i>
													</button>
												</div>
											</template>
										</template>
										<button class="btn subBtn marginLeft0 fontSize12">
											<span>More</span>&nbsp;<i class="fa fa-sort-down"></i>
										</button>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div> -->
        <div
            :id="'eGalleryTeamInfoModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryTeamInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h4 :id="'eGalleryTeamInfoModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-else-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h4>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Elaboration</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="marginTop20">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                            </div>
                            <div>
                                <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                    <div v-for="(file, idx) in previewModel.attachments" class="flex">
                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                    </div>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj', 'question', 'options'],
    data() {
        return {
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
            },
        };
    },
    methods: {
        openModelInfoModal(model, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = model.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = model.student;
            }
            this.previewModel.comment = model.comment;
            this.previewModel.attachments = model.attachments ? model.attachments : [];
            $('#eGalleryTeamInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryTeamInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        truncate(str, characters = 10) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
    },
};
</script>

<style>
body.test .panel {
    height: 100%;
}
</style>

<style scoped>
body.test .mini-panel .panel-heading {
    font-size: 14px;
}

body.test .mini-panel .panel-heading .link {
    /* color: #0071be; */
    text-transform: capitalize;
}

@media (min-width: 992px) {
    .btnSections {
        margin: 5px;
    }
}

@media (max-width: 991px) {
    .btnSections {
        margin-top: 10px;
    }
    .btnSections:first-child {
        margin-top: 0;
    }
}

body.test .discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

body.test .mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams {
    white-space: normal;
}

.commentTeams div {
    margin-top: 15px;
}

.commentTeams div:first-child {
    margin-top: 0;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 10px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}
table.barChart {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: 330px;
    height: 330px;
    position: relative;
    overflow: auto;
    border: none;
    box-shadow: none;
}

.barChart .bars {
    display: flex;
    flex-direction: column;
}

.barChart .bars tr {
    flex-grow: 1;
}

.barChart .bars td {
    text-align: center;
    display: block;
    margin-right: 0.5em;
    line-height: 0em;
}

.barChart .bars td::after {
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    position: absolute;
}

.barChart .body {
    display: flex;
    flex-grow: 1;
    height: 200px;
    border-bottom: 2px solid #d8d8d8;
}

.barChart .body tr {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 2% 0 0;
    position: relative;
    width: 150px;
    height: 200px;
}

.barChart .body tr:first-child {
    margin-left: 2%;
}

.barChart .record {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 200px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0071be;
    font-weight: bold;
    line-height: 1;
}

.btnDiscussionTeams {
    margin-top: 10px;
}

.btnDiscussionTeams:first-child {
    margin-top: 0;
}
.barChart .bars.correct {
    background: #3e7c5b;
}
.barChart .bars.wrong {
    background: #ca3333;
}
.barChart .bars.default {
    background: #717171;
}
.responseFont {
    /* margin-top:8px; */
    margin-top: 0;
    margin-right: 10px;
    font-weight: bold;
}

.discussionOption .discussion-buttons button i {
    color: #fff;
}
</style>
